import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Logo from "../images/logo.png";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  _gotoTop() {
    window.location.href = "#Home";
  }

  _renderBrand() {
    return (
      <div className="MenuWrap">
        <ul>
          <li className={ this.props.section === "Home" ? "select" : "" }>
            <Link to={{ pathname: "/brand", hash: "Home" }} title="HOME" onClick={e => this.props.onClick("Home")}>HOME</Link>
          </li>
          <li className={ this.props.section === "Brand" ? "select" : "" }>
            <Link to={{ pathname: "/brand", hash: "Brand" }} title="BRAND" onClick={e => this.props.onClick("Brand")}>BRAND</Link>
          </li>
          <li className={ this.props.section === "Menu" ? "select" : "" }>
            <Link to={{ pathname: "/brand", hash: "Menu" }} title="MENU" onClick={e => this.props.onClick("Menu")}>MENU</Link>
          </li>
          <li className={ this.props.section === "Store" ? "select" : "" }>
            <Link to={{ pathname: "/brand", hash: "Store" }} title="STORE" onClick={e => this.props.onClick("Store")}>STORE</Link>
          </li>
          <li className={ this.props.section === "News" ? "select" : "" }>
            <Link to={{ pathname: "/brand", hash: "News" }} title="NEWS" onClick={e => this.props.onClick("News")}>NEWS</Link>
          </li>
          <li>
            <Link to="/franchise" title="FRANCHISE"><span>가맹안내</span></Link>
          </li>
        </ul>
      </div>
    ) 
  }

  _renderFranchise() {
    return (
      <div className="MenuWrap">
        <ul>
          <li className={ this.props.section === "Home" ? "select" : "" }>
            <Link to={{ pathname: "/franchise", hash: "Home" }} title="HOME" onClick={e => this.props.onClick("Home")}>HOME</Link>
          </li>
          <li className={ this.props.section === "Competitive" ? "select" : "" }>
            <Link to={{ pathname: "/franchise", hash: "Competitive" }} title="COMPETITIVE" onClick={e => this.props.onClick("Competitive")}>경쟁력</Link>
          </li>
          <li className={ this.props.section === "Open" ? "select" : "" }>
            <Link to={{ pathname: "/franchise", hash: "Open" }} title="OPEN" onClick={e => this.props.onClick("Open")}>창업절차</Link>
          </li>
          <li className={ this.props.section === "Consulting" ? "select" : "" }>
            <Link to={{ pathname: "/franchise", hash: "Consulting" }} title="CONSULTING" onClick={e => this.props.onClick("Consulting")}>상담신청</Link>
          </li>
          <li className={ this.props.section === "Brand" ? "select" : "" }>
            <Link to="/brand" title="BRAND"><span>브랜드 소개</span></Link>
          </li>
        </ul>
      </div>
    )
  }

  render() {
    return (
      <div id="Header" className="Header">
        <div className="HeaderArea">
          <Link className="LogoLink" to="/">
            <img src={ Logo } className="Logo" alt="Logo" />
          </Link>
          { this.props.type === "BRAND" ? this._renderBrand() : this._renderFranchise() }
        </div>
        { this.props.type === "FRANCHISE" ? <div className="Border"></div> : "" }
      </div>
    )
  }
}

export default Header;