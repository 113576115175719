import React, { Component, Fragment } from "react";
import * as Apis from "../components/Apis";
import moment from 'moment';
import Loading from '../components/Loading';

class Checklist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      checklist_id: 0,
      memberName: "",
      branch_organization_member_id: 0,
      branch_id: 0,
      checklist: null,
      checklists: null,
      checklistCategories: null,
      checklistDetails: null,
      branches: null,
      step: 1,
      regdate: moment().format('YYYY-MM-DD'),
      todayFormat: moment().format('YYYY. MM. DD (dd)'),
      checklistChecks: [],
      checklistMembers: []
    };
  }

  componentDidMount() {
    this._listChecklist();
    this._listChecklistMember();
  }

  _getChecklist = (id) => {
    Apis.getChecklist(id).then(({ loading, data: { result, resultCode, resultMessage } }) => {
      if (resultCode === "S1000") {
        if(result.entity) {
          this.setState({ checklist: result.entity });

          let checks = [];
          let categories = result.entity.checklistCategories;
          if(categories && categories.length !== 0) {
            for(let i in categories) {
              let category = categories[i];
              let details = category.checklistDetails;

              let checks2 = [];
              if(details && details.length !== 0) {
                for(let j in details) {
                  let detail = details[j];
                  checks2.push({ id: detail.id, isCheck: null, imgFile: null, imgFileName: "" });
                }
              }
              checks.push(checks2);
            }
          }

          this.setState({ 
            checklistCategories: categories,
            checklistChecks: checks
          });
        }
      } else {
        console.log(resultMessage);
      }
    });
  }

  _listChecklist = () => {
    Apis.listChecklist().then(({ loading, data: { result, resultCode, resultMessage } }) => {
      if (resultCode === "S1000") {
        if(result.entities) {
          this.setState({ checklists: result.entities });
        }
      } else {
        console.log(resultMessage);
      }
    });
  }

  _listChecklistMember = () => {
    Apis.listChecklistMember().then(({ loading, data: { result, resultCode, resultMessage } }) => {
      if (resultCode === "S1000") {
        if(result.entities) {
          this.setState({ checklistMembers: result.entities });
        }
      } else {
        console.log(resultMessage);
      }
    });
  }

  _listBranch = () => {
    Apis.listBranch().then(({ loading, data: { result, resultCode, resultMessage } }) => {
      if (resultCode === "S1000") {
        if(result.entities) {
          this.setState({ branches: result.entities });
        }
      } else {
        console.log(resultMessage);
      }
    });
  }

  _doConfirmChecklist = () => {
    if(parseInt(this.state.checklist_id) === 0) {
      alert('체크리스트를 선택해주세요.');
      return;
    }

    if(parseInt(this.state.branch_organization_member_id) === 0) {
      alert("점검자를 선택해주세요.");
      return;
    }

    this.setState({ step: 2 });

    this._listBranch();
    this._getChecklist(this.state.checklist_id);
  }

  _setChecklistValue = (idx1, idx2, e) => {
    let checklistChecks = this.state.checklistChecks;
    let targetObj = checklistChecks[idx1][idx2];
    targetObj.isCheck = parseInt(e.target.value);
    checklistChecks[idx1].splice(idx2, 1, targetObj);
    this.setState({ checklistChecks: checklistChecks });
  }

  _openFileUpload = (idx1, idx2) => {
    let imgFile = this.state.checklistChecks[idx1][idx2].imgFile;
    if(imgFile) {
      let checklistChecks = this.state.checklistChecks;
      let targetObj = checklistChecks[idx1][idx2];
      targetObj.imgFile = null;
      targetObj.imgFileName = "";
      checklistChecks[idx1].splice(idx2, 1, targetObj);
      this.setState({ checklistChecks: checklistChecks });
    } else {
      this["inputFile_" + idx1 + "_" + idx2].click();
    }
  }

  _selectFile = (idx1, idx2, e) => {
    let checklistChecks = this.state.checklistChecks;
    let targetObj = checklistChecks[idx1][idx2];
    targetObj.imgFile = e.target.files[0];
    targetObj.imgFileName = e.target.files[0].name;
    checklistChecks[idx1].splice(idx2, 1, targetObj);
    this.setState({ checklistChecks: checklistChecks });
  }

  _doSubmit = () => {
    if(window.confirm("등록하시겠습니까?")) {
      if(this.state.branch_id === 0) {
        alert("지점을 선택해주세요.");
        return;
      }
  
      let formData = new FormData();
      formData.append("branch_id", this.state.branch_id);
      formData.append("checklist_id", this.state.checklist_id);
      formData.append("branch_organization_member_id", this.state.branch_organization_member_id);

      let checklistChecks = this.state.checklistChecks;
      for(let idx1 in checklistChecks) {
        let checklistCheck1 = checklistChecks[idx1];
        for(let idx2 in checklistCheck1) {
          let checklistCheck2 = checklistCheck1[idx2];
          if(checklistCheck2.isCheck === null) {
            alert("체크하지않은 항목이 있습니다.");
            return;
          }
  
          formData.append("checklist_detail_ids", checklistCheck2.id);
          formData.append("isChecks", checklistCheck2.isCheck);
          formData.append("imgFiles", checklistCheck2.imgFile);
          formData.append("imgFileNames", checklistCheck2.imgFileName);
        }
      }

      this.setState({ isLoading: true });
      Apis.insertChecklist(formData).then(({ loading, data: { result, resultCode, resultMessage } }) => {
        this.setState({ isLoading: false });
        if (resultCode === "S1000") {
          alert("등록되었습니다.");
          this._init();
        } else {
          alert(resultMessage ? resultMessage : "문제가 발생하였습니다.<br/>다시 시도 해주세요.");
        }
      });
    }
  }

  _doCancel = () => {
    if(window.confirm("취소하시겠습니까?")) {
      this._init();
    }
  }

  _init() {
    this.setState({
      checklist_id: 0,
      memberName: "",
      branch_organization_member_id: 0,
      branch_id: 0,
      checklist: null,
      checklistDetails: null,
      branches: null,
      step: 1,
      checklistChecks: [],
      checklistMembers: []
    });
    window.location.reload();
  }

  render() {
    return (
      <div id="ChecklistComponent">
        {
          this.state.step === 1 && <div className="ChecklistEnterWrap">
            <select onChange={(e) => this.setState({ checklist_id: e.target.value })}>
              <option value="0">체크리스트 선택</option>
              { this.state.checklists && this.state.checklists.map((entity, idx) => (
                <option key={idx} value={entity.id}>{entity.title}</option>
              )) }
            </select>
            <select style={{marginTop: "10px"}} onChange={(e) => this.setState({ branch_organization_member_id: e.target.value, memberName: e.target.selectedOptions[0].text })}>
              <option value="0">점검자 선택</option>
              { this.state.checklistMembers && this.state.checklistMembers.map((entity, idx) => (
                <option key={idx} value={entity.branch_organization_member_id}>{entity.memberName}</option>
              )) }
            </select>
            <button onClick={() => this._doConfirmChecklist()}>체크리스트 작성</button>
          </div>
        }

        {
          this.state.step === 2 && <Fragment>
            <div className="ChecklistHeader">
              <p className="Title">{this.state.checklist && this.state.checklist.title}</p>
              <select onChange={(e) => this.setState({ branch_id: e.target.value }) }>
                <option value="0">지점 선택</option>
              { this.state.branches && this.state.branches.map((entity, idx) => (
                <option key={idx} value={entity.id}>{entity.branchName}</option>
              )) }
              </select>
              <div className="Info">
                <p>{this.state.todayFormat}</p>
                <p>{this.state.memberName}</p>
              </div>
            </div>

            <div className="ChecklistWrap">
            {
              this.state.checklistCategories && this.state.checklistCategories.map((category, idx1) => (
                <div key={idx1} className="ChecklistCategory">
                  <div className="Title">
                    <p>{category.title}</p>
                  </div>

                  { category.checklistDetails && category.checklistDetails.map((entity, idx2) => (
                    <div key={idx2} className="ChecklistRow">
                      <div className="Title">
                        <p dangerouslySetInnerHTML={{__html: entity.title}}></p>
                      </div>
                      <div className="CheckWrap">
                        <input type="radio" name={"check_" + idx1 + "_" + idx2} id={"check1_" + idx1 + "_" + idx2} value={1} onChange={(e) => this._setChecklistValue(idx1, idx2, e)} />
                        <label htmlFor={"check1_" + idx1 + "_" + idx2}>실시</label>
                        <input type="radio" name={"check_" + idx1 + "_" + idx2} id={"check2_" + idx1 + "_" + idx2} value={0} onChange={(e) => this._setChecklistValue(idx1, idx2, e)} />
                        <label htmlFor={"check2_" + idx1 + "_" + idx2}>미실시</label>

                        <button className={"FileUpload" + (this.state.checklistChecks[idx1][idx2].imgFile ? " Exist": "")} onClick={() => this._openFileUpload(idx1, idx2)}>{this.state.checklistChecks[idx1][idx2].imgFile ? "삭제" : "첨부"}</button>
                        <input type="file" ref={ref => {this["inputFile_" + idx1 + "_" + idx2] = ref}} onChange={(e) => this._selectFile(idx1, idx2, e)} />
                      </div>
                    </div>
                  )) }
                </div>
              ))
            }
            </div>

            <div className="ButtonGroupBottom">
              <button className="CancelChecklist" onClick={() => this._doCancel()}>취소</button>
              <button className="SubmitChecklist" onClick={() => this._doSubmit()}>체크리스트 등록</button>
            </div> 
          </Fragment>
        }

        {this.state.isLoading && <Loading />}
      </div> 
    )
  }
}

export default Checklist;