import React, { Component } from "react";
import { Link } from 'react-router-dom';
import * as Apis from "../components/Apis";
import Slider from "react-slick";
import Header from "./Header";
import Footer from "./FooterNew";

const settingsForStore = {
  arrows: false,
  infinite: true,
  speed: 700,
  useCSS: true,
  fade: true,
};

class Store extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      size: 12,
      totalCnt: 0,
      storeType: "",
      entities: [],
      isMore: false,
      entity: null,
      isDialogOpen: false,
      storeNextIdx: 0,
    };
  }

  componentDidMount() {
    let storeType = this.props.match.params.storeType;
    this.setState({ storeType: storeType });
    this._listStore(storeType, 1, this.state.size);

    let storeId = this.props.match.params.storeId;
    console.log(storeId);
    if(typeof storeId !== 'undefined' && storeId !== 0) {
      this._getStore(storeId);
    }
  }

  componentDidUpdate(prevProps) {
    let storeType = this.props.match.params.storeType;
    if (storeType !== prevProps.match.params.storeType) {
      this.setState({ storeType: storeType });
      this._listStore(storeType, 1, this.state.size);
    }
  }

  _getStore(id) {
    Apis.getStore(id).then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        this.dialogOpen(result.entity);
			} else {
				console.log(resultMessage);
      }
    });
  }

  _addList() {
    this._listStore(this.state.storeType, this.state.page + 1, this.state.size);
  }

  _listStore(storeType, page, size) {
    Apis.listStore(storeType, page, size).then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        let _entities = ( page === 1 ? result.entities : [...this.state.entities, ...result.entities] );
        this.setState({
          page: page,
          totalCnt: result.totalCnt,
          entities: _entities,
          isMore: result.totalCnt !== _entities.length
        });
			} else {
				console.log(resultMessage);
      }
    });
  }

  setMap(latitude, longitude, storeName) {
    window.daum.maps.load(() => {
      var position = new window.daum.maps.LatLng(latitude, longitude);
      var container = document.getElementById('Map');
      var options = {
        center: position,
        level: 3
      };

      var map = new window.daum.maps.Map(container, options);
      map.addControl(new window.daum.maps.ZoomControl(), window.daum.maps.ControlPosition.RIGHT);

      var marker = new window.daum.maps.Marker({
        position: position,
        map: map
      });
      marker.setMap(map);

      var infowindow = new window.daum.maps.InfoWindow({
        position: position,
        content: '<div style="width: 152px;margin: 5px 0 5px 0;text-align: center;font-size: 14px;color: #888;">' + storeName + '</div>'
      });
      infowindow.open(map, marker);
    });
  }

  dialogOpen(entity) {
    if(entity) {
      this.setState({
        entity: entity,
        isThumbTwo: entity.storeThumbImages && entity.storeThumbImages.length === 2,
        isDialogOpen: true
      });
      
      let _self = this;
      let timer = setTimeout(function(){
        _self.setMap(entity.latitude, entity.longitude, entity.storeName);
        _self.moveSlideThumb(0);
        clearTimeout(timer);
      }, 500);
    }
  }

  dialogClose(e) {
    if(e.key.toLowerCase().indexOf("esc") !== -1 && this.state.isDialogOpen) {
      this.setState({ 
        entity: null,
        isDialogOpen: false
      });
    }
  }

  beforeChange(before, next) {
    this.setState({ storeNextIdx: next });
    this.storeSlide.slickGoTo(next);
  }

  moveSlideThumb(idx) {
    this.storeSlideThumb && this.storeSlideThumb.slickGoTo(idx);
  }

  render() {
    return (
      <div id="BrandComponent" onKeyDown={(e) => this.dialogClose(e)} tabIndex="0">
        <Header type="BRAND" section="Store" onClick={() => {}} />

        <div className="Container">
          <div id="StoreList" className="Anchor" ref={ref => {this.Store = ref}}></div>
          <div className="StoreList">
            <div className="SubContainer">
              <div className="TitleWrap">
                <p className="Title">STORE</p>
                <ul>
                  <li className={this.state.storeType === "all" ? "Select" : ""}>
                    <Link to="/store/all" title="전체보기">전체보기</Link>
                  </li>
                  <li className={this.state.storeType === "shop" ? "Select": ""}>
                    <Link to="/store/shop" title="점포형">점포형</Link>
                  </li>
                  <li className={this.state.storeType === "install" ? "Select" : ""}>
                    <Link to="/store/install" title="설치형">설치형</Link>
                  </li>
                </ul>
              </div>
              <div className="ListView">
                <div className="ListWrap">
                  { this.state.entities ? 
                    <ul>
                      { this.state.entities.map((entity, idx) => (
                        <li key={idx} className={ entity.storeType } onClick={() => this.dialogOpen(entity)}>
                          <div className={"TypeBox " + entity.storeType}><div>{ entity.storeType === "shop" ? "점포형" : "설치형" }</div></div>
                          <div className="TitleBox">
                            <p className="Title" dangerouslySetInnerHTML={{ __html: entity.title }}></p>
                            <p className="StoreName">{ entity.storeName }</p>
                          </div>
                          <div className="Desc">{ entity.subtitle }</div>
                          <div className={"Go " + entity.storeType}>
                            <span>GO</span>
                          </div>
                        </li>
                      ))}
                    </ul> :
                    <p className="Nodata">등록된 게시물이 없습니다.</p>
                  }
                </div>
                { this.state.isMore ? 
                  <div className="BtnWrap">
                    <button className="More" onClick={() => this._addList()}>더보기 +</button>
                  </div> : ""
                }
              </div>
            </div>
          </div>

          <div className="Anchor"></div>
          <div id="FranchiseNewComponent">
            <Footer />
          </div>
        </div>

        { 
          this.state.entity && 
          <div id="Dialog" className={"LayerWrap " + (this.state.entity && this.state.isDialogOpen ? "on" : "" )}>
            <div className={"StorePopLayer " + (this.state.entity && this.state.entity.storeType)}>
              <button className="BtnClose" onClick={() => this.setState({ isDialogOpen: false, entity: null })}></button>
              <div className="TopBg">
                <Slider ref={ref => this.storeSlide = ref} className="Slide" {...settingsForStore}>
                  {this.state.entity.storeImages.map((url, index) => {
                    return <img key={index} className="Bg" src={ url } alt="" />
                  })}
                </Slider>

                { this.state.entity.storeThumbImages &&
                  <div className={`ThumbnailWrap ${this.state.isThumbTwo ? 'Two' : 'Three'}`}>
                    <Slider ref={ref => this.storeSlideThumb = ref} beforeChange={(current, next) => this.beforeChange(current, next)} {...{
                      arrows: false,
                      infinite: true,
                      speed: 700,
                      autoplay: true,
                      autoplaySpeed: 3000,
                      slidesToShow: this.state.isThumbTwo ? 2 : 3,
                      slidesToScroll: 1,
                      pauseOnHover: true,
                    }}>
                      {
                        this.state.entity.storeThumbImages.map((url, index) => {
                          return <div key={index} className="Thumbnail" onClick={() => this.moveSlideThumb(index)}>
                            <img key={index} className={`${this.state.storeNextIdx === index && 'Active'}`} src={ url } onClick={() => this.moveSlideThumb(index)} alt="" />
                          </div>
                        })
                      }
                    </Slider>
                  </div>
                }
              </div>
              <div className="ContentWrap">
                <div className="Top PC">
                  <div className="Left">
                    <span dangerouslySetInnerHTML={{ __html: this.state.entity.title }}></span>
                  </div>
                  <div className="Right"></div>
                </div>
                <div className={`StoreName ${this.state.entity.storeThumbImages && this.state.entity.storeThumbImages.length > 1 && 'ThumbMargin'}`}>
                  <p className="MOBILE" dangerouslySetInnerHTML={{ __html: this.state.entity.title }}></p>
                  <span>{ this.state.entity.storeName }</span>
                  <span>{ this.state.entity.storeType === "shop" ? "" : "설치형" }</span>
                </div>
                <div className="Main">
                  <div className="MainWrap">
                    { 
                      this.state.entity.operationTime && 
                      <div className="ContentRow">
                        <div className="Title">운영시간</div>
                        <div className="Content">{ this.state.entity.operationTime }</div>
                      </div>
                    }
                    
                    { 
                      this.state.entity.address && 
                      <div className="ContentRow">
                        <div className="Title">주소</div>
                        <div className="Content">{ this.state.entity.address } { this.state.entity.addressDetail }</div>
                      </div>
                    }

                    { 
                      this.state.entity.introduce && 
                      <div className="ContentRow">
                        <div className="Title">지점소개</div>
                        <div className="Content" dangerouslySetInnerHTML={{ __html: this.state.entity.introduce }}></div>
                      </div>
                    }

                    { 
                      this.state.entity.directions && 
                      <div className="ContentRow">
                        <div className="Title">찾아 오시는 길</div>
                        <div className="Content" dangerouslySetInnerHTML={{ __html: this.state.entity.directions }}></div>
                      </div>
                    }

                  </div>

                  {
                    this.state.entity.latitude && this.state.entity.longitude &&
                    <div id="Map" className="Map"></div>
                  }

                  <div className="Logo"></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div> 
    )
  }
}

export default Store;