import React, { Component, Fragment } from "react";
import * as Apis from "../components/Apis";
import * as Common from '../components/Common';
import Loading from '../components/Loading';
import DaumPostcodeEmbed from 'react-daum-postcode';
import Banner1 from "../images/event/2207/1/banner.png";
import Banner2 from "../images/event/2207/2/banner.png";
import Gift1 from "../images/event/2207/1/gift.png";
import Gift2 from "../images/event/2207/2/gift.png";

class Event extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEventEnd: true,
      step: '0',
      isPostcodeOpen: false,
      isPopupOpen: false,
      isDialogOpen: false,
      isLoading: false,
      callbackFunc: null,
      dialogType: "Alert",
      dialogMsg: "",
      userName: "",
      postcode: "",
      address: "",
      addressDetail: "",
      userTel: "",
      userCertNum: "",
      certNum: "",
      url: "",
      store_id: "",
      branches: null,
      isUserName: true,
      isAddress: true,
      isAddressDetail: true,
      isUserTel: true,
      isValidUserTel: true,
      isUserCertNum: true,
      isValidCertNum: true,
      isCert: "",
      isUrl: true,
      isStoreId: true,
      isCheckAgree: false,
      isAgree: "",
      certTxt: "인증",
      isSubmit: false,
    };
  }

  componentDidMount() {
    this._doOpenDialog("이벤트가 종료되었습니다.", "Alert", this._goHome);
    return;

    let _pathnames = this.props.location.pathname.split('/');
    let _step = _pathnames[_pathnames.length - 1];
    if(_step !== '1' && _step !== '2') {
      window.location.href = "/";
      return;
    }

    this.setState({ step: _step });
    this._listStore();

    if(_step === '2') {
      // 2차 참여시 1차때 참여한 정보 불러옴
      let _userName = localStorage.getItem("userName");
      let _userTel = localStorage.getItem("userTel");
      if(_userName && _userTel) {
        this._doOpenDialog("1차 이벤트 참여정보를<br/>불러오시겠습니까?", "Confirm", this._getEventWedAmericano);
      }
    }
  }

  _goHome = () => {
    window.location.href = "/";
    return;
  }


  /**
   * 1차 참여정보 조회
   */
   _getEventWedAmericano = () => {
    let _userName = localStorage.getItem("userName");
    let _userTel = localStorage.getItem("userTel");
    Apis.getEventWedAmericano(_userName, _userTel).then(({ loading, data: { result, resultCode, resultMessage } }) => {
      if (resultCode === "S1000") {
        let _entity = result.entity;
        if(_entity) {
          this.setState({
            userName: _entity.userName,
            userTel: _entity.userTel,
            postcode: _entity.postcode,
            address: _entity.address,
            addressDetail: _entity.addressDetail,
            url: _entity.url1,
            store_id: _entity.store_id
          });
          this.setState({ isDialogOpen: false });
        }
      } else {
        console.log(resultMessage);
      }
    });
  }

  /**
   * 지점 리스트 조회
   */
  _listStore() {
    Apis.listEventWedAmericanoStore().then(({ loading, data: { entities, resultCode, resultMessage } }) => {
			if (resultCode.toLowerCase() === "success") {
        this.setState({ branches: entities });
			} else {
				console.log(resultMessage);
      }
    });
  }

  /**
   * 인증번호 조회
   */
  _getCertNumber() {
    this.setState({
      isUserTel: true,
      isValidUserTel: true,
      certNum: "",
      userCertNum: "",
      certTxt: "인증"
    });

    let _userTel = this.state.userTel;
    if(!_userTel) {
      this.setState({ isUserTel: false });
      return;
    }
    let _isValidUserTel = Common.isPhoneNumberValidation(_userTel);
    if(!_isValidUserTel) {
      this.setState({ isValidUserTel: false });
      return;
    }
    Apis.getCertNumber(this.state.userTel).then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        this.setState({ certNum: result.verifyNum });
			} else {
				console.log(resultMessage);
      }
    });
  }

  /**
   * 인증번호 체크
   * @returns 
   */
  _checkCertNumber() {
    this.setState({
      isUserCertNum: true,
      isValidCertNum: true,
      isCert: ""
    });

    let _certNum = this.state.certNum;
    let _userCertNum = this.state.userCertNum;
    if(!_userCertNum) {
      this.setState({ isUserCertNum: false });
      return;
    }
    let _isValidCertNum = _certNum === _userCertNum;
    this.setState({ isValidCertNum: _isValidCertNum });
    if(_isValidCertNum) this.setState({ isCert: true, certTxt: "인증완료" });
  } 

  /**
   * 페이지 리로드
   */
  _reload() {
    window.location.reload();
  }

  /**
   * 다이얼로그 얼럿 열기
   * @param {메시지} msg 
   * @param {다이얼로그 타입 Alert : 확인, Confirm : 확인, 취소} dialogType 
   */
  _doOpenDialog(msg, dialogType = "Alert", callbackFunc) {
    this.setState({
      isDialogOpen: true,
      dialogMsg: msg,
      dialogType: dialogType,
      callbackFunc: callbackFunc
    });
  }

  /**
   * 다이얼로그 얼럿 닫기
   */
  _doCloseDialog() {
    if(this.state.dialogType === "Alert" && this.state.isEventEnd) {
      window.location.href = "/";
      return;
    }

    this.setState({ isDialogOpen: false });

    let timer = setTimeout(() => {
      if(this.state.dialogType === "Complete" || this.state.dialogType === "Cancel") {
        this._reload();
      }

      if(this.state.dialogType === "Alert" && this.state.callbackFunc) {
        this.state.callbackFunc();
        return;
      }

      clearTimeout(timer);
    }, 500);
  }

  /**
   * 팝업오픈(개인정보수집 및 이용 팝업)
   * @param {true: 열기, false: 닫기} isOpen 
   */
  _doOpenPopup(isOpen) {
    this.setState({ isPopupOpen: isOpen });
  }

  /**
   * 참여 확인 팝업
   */
  _doSubmitConfirm = () => {
    this._doOpenDialog("이벤트가 종료되었습니다.", "Alert", this._goHome);
    return;
    // this._doOpenDialog("이벤트에 참여하시겠습니까?", "Confirm", this._doSubmit);
  }

  _doConfirm = () => {
    if(this.state.callbackFunc) this.state.callbackFunc();
  }

  /**
   * 참여하기
   */
  _doSubmit = () => {
    this.setState({ isSubmit: true });

    let _userTel = this.state.userTel;
    if( !this.state.userName || !this.state.address || !this.state.addressDetail
      || !_userTel || !Common.isPhoneNumberValidation(_userTel) || !this.state.isCert
      || !this.state.url || !this.state.store_id || !this.state.isAgree
    ) {
      this.setState({ isDialogOpen: false });
      return;
    }

    this.setState({ isDialogOpen: false, isLoading: true });
    Apis.insertEventWedAmericano(this.state.step, this.state.userName, this.state.userTel, this.state.postcode, this.state.address, this.state.addressDetail, this.state.url, this.state.store_id).then(({ loading, data: { result, resultCode, resultMessage } }) => {
      this.setState({ isLoading: false });
			if (resultCode === "S1000") {
        let msg = "이벤트 참여가 완료되었습니다.";
        if(this.state.step === '1') {
          msg += "<br/>경품은 참여 내용 확인 후 발송됩니다.";

          // 1차 참여 완료후 저장해뒀다가 2차 참여때 불러오는 용도
          window.localStorage.setItem("userName", this.state.userName);
          window.localStorage.setItem("userTel", this.state.userTel);
        } else if(this.state.step === '2') {
          msg += "<br/>* 당첨자발표: 9/16(금)<br/>* 당첨자에 한해 개별 연락";
          // 2차
          window.localStorage.removeItem("userName");
          window.localStorage.removeItem("userTel");
        }
        this._doOpenDialog(msg, "Complete");
			} else {
				this._doOpenDialog(resultMessage);
      }
    });
  }

  /**
   * 참여취소
   */
  _doSubmitCancel() {
    this._doOpenDialog("이벤트 참여를<br/>취소하시겠습니까?", "Cancel");
  }

  /**
   * 주소찾기 팝업
   * @param {*} data 
   */
  _handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      isPostcodeOpen: false,
      postcode: data.zonecode,
      address: fullAddress
     });
  }

  /** url 한글체크 */
  _doUrlCheck = (e) => {
    let val = e.target.value;
    if( Common.isHangul(val) ) {
      e.preventDefault(); return;
    }

    this.setState({ url: e.target.value })
  }

  render() {
    return (
      <div id="EventComponent">
        {
          this.state.step !== '0' && (
            this.state.step === '1' ?
            <Fragment>
              <div className="BannerWrap">
                <img src={Banner1} alt="" />
              </div>
              <div className="DescWrap">
                <p className="TopDesc">패스트카페 커피를 구매해주셔서 감사합니다.</p>
                <p>경품을 받으실 정보와 인스타그램 게시 인증 URL을 입력해주시면 맛있는 “스페셜티 드립백 꾸러미”를 100% 받아보실 수 있습니다.<br/>
                (필수 해시태그 : #패스트카페 #커피맛집 #드립백커피 #드립백추천)
                </p>
              </div>
              <div className="GiftWrap">
                <img src={Gift1} alt="" />
              </div>
              <div className="DescWrap">
                <p>* 경품은 참여일 기준 2~3일 내로 발송됩니다 *<br/>
                * 비정상적인 게시물은 이벤트 참여가 불가합니다. *<br/>
                * 잘못된 정보를 입력하여 경품이 반송되는 경우 재발송되지 않습니다. *<br/>
                * 정확한 택배 수령일은 택배사 문자를 통해 확인해주세요. *
                </p>
                <p className="BottomDesc">이벤트 문의 : <a href="mailto:event@toz.co.kr">event@toz.co.kr</a></p>
              </div>
            </Fragment> : 
            <Fragment>
              <div className="BannerWrap">
                <img src={Banner2} alt="" />
              </div>
              <div className="DescWrap">
                <p className="TopDesc">이벤트에 참여해주셔서 감사합니다.</p>
                <p>수령하신 스페셜티 드립백 꾸러미를 인스타그램에 게시 후 경품을 받으실 정보와 인스타그램 게시 인증 URL을 입력해주시면 BEST 인증 1명 아이패드, 2명을 추첨하여 에어팟을 선물로 드립니다.<br/>
                (필수 해시태그 : #패스트카페 #커피맛집 #드립백커피 #드립백추천)
                </p>
              </div>
              <div className="GiftWrap">
                <img src={Gift2} alt="" />
              </div>
              <div className="DescWrap">
                <p className="TopDesc">당첨자발표: 9/16(금). 개별 연락 예정</p>
                <p>*제세공과금은 본인 부담입니다. *<br/>
                * 비정상적인 게시물은 이벤트 참여가 불가합니다. *
                </p>
                <p className="BottomDesc">이벤트 문의 : <a href="mailto:event@toz.co.kr">event@toz.co.kr</a></p>
              </div>
            </Fragment>
          )
        }
        <div className="Container">
            <div className="TitleWrap">
                <p>수집정보</p>
            </div>
            <div className="Row">
            <div className="Title">1. 수령받으실 고객님의 이름을 입력해주세요.</div>
            <div className="Input">
              <input type="text" name="userName" placeholder="이름 입력" onChange={e => this.setState({ userName: e.target.value })} value={this.state.userName} />
            </div>
            { this.state.isSubmit && !this.state.userName && <p className="Warning">이름을 입력해주세요.</p> }
          </div>
          <div className="Row">
            <div className="Title">2. 수령받으실 주소를 입력해주세요.</div>
            <div className="Group">
              <div className="Input">
                <input type="text" name="address" placeholder="주소 입력" readOnly value={ this.state.address } />
              </div>
              <button onClick={() => this.setState({ isPostcodeOpen: true })}>주소찾기</button>
            </div>
            { this.state.isSubmit && !this.state.address && <p className="Warning">주소를 입력해주세요.</p> }
            <div className="Input Second">
              <input type="text" name="addressDetail" placeholder="상세주소 입력" onChange={e => this.setState({ addressDetail: e.target.value })} value={this.state.addressDetail} />
            </div>
            { this.state.isSubmit && !this.state.addressDetail && <p className="Warning">상세주소를 입력해주세요.</p> }
          </div>
          <div className="Row">
            <div className="Title">3. 안내를 위한 연락처를 입력해주세요.</div>
            <div className="Group">
              <div className="Input">
                <input type="text" name="userTel" maxLength="11" placeholder="연락처 입력" readOnly={this.state.certNum && this.state.isCert ? "readonly" : ""} onChange={e => this.setState({ userTel: Common.replaceNum(e.target.value) }) } value={this.state.userTel} />
              </div>
              <button disabled={this.state.certNum && this.state.isCert ? "disabled" : ""} onClick={() => this._getCertNumber()}>인증번호 발송</button>
            </div>
            { this.state.isSubmit && !this.state.userTel && <p className="Warning">연락처를 입력해주세요.</p> }
            { this.state.isSubmit && (this.state.userTel && !Common.isPhoneNumberValidation(this.state.userTel)) && <p className="Warning">올바른 연락처 형식이 아닙니다.</p> }
            <div className="Group Second">
              <div className="Input">
                <input type="text" name="userCertNum" maxLength="6" placeholder="인증코드 입력" readOnly={this.state.certNum && this.state.isCert ? "readonly" : ""} onChange={e => this.setState({ userCertNum: Common.replaceNum(e.target.value) }) } />
              </div>
              <button disabled={!this.state.certNum || this.state.isCert ? "disabled" : ""} onClick={() => this._checkCertNumber()}>{this.state.certTxt}</button>
            </div>
            { !this.state.isUserCertNum && <p className="Warning">인증코드를 입력해주세요.</p> }
            { !this.state.isValidCertNum && <p className="Warning">인증코드가 일치하지 않습니다.</p> }
            { this.state.isSubmit && !this.state.isCert && <p className="Warning">인증을 완료해주세요.</p> }
          </div>
          <div className="Row">
            <div className="Title">4. 작성하신 게시물에 대한 URL을 입력해주세요.</div>
            <div className="Input">
              <input type="text" name="url" placeholder="URL 입력" onChange={e => this._doUrlCheck(e)} value={this.state.url} />
            </div>
            { this.state.isSubmit && !this.state.url && <p className="Warning">URL을 입력해주세요.</p> }
          </div>
          { this.state.step === '1' &&
            <div className="Row">
              <div className="Title">5. 구매하신 가맹점을 선택해주세요.</div>
              <div className="Select">
                <select name="store_id" onChange={e => this.setState({ store_id: e.target.value })} value={this.state.store_id}>
                  <option value="">가맹점 선택</option>
                  { this.state.branches && this.state.branches.map((obj, idx) => ( <option key={idx} value={ obj.id }>{ obj.storeName }</option> )) }
                </select>
              </div>
              { this.state.isSubmit && !this.state.store_id && <p className="Warning">가맹점을 선택해주세요.</p> }
            </div>
          }
          <div className="Row Checkbox">
            <label className="Label"> 개인정보 수집 및 이용에 동의합니다.
              <input type="checkbox" name="isAgree" onChange={() => this.setState({ isAgree: !this.state.isAgree })} />
              <span className="Checkmark"></span>
            </label>
            <button className="PrivacyLink" onClick={() => this._doOpenPopup(true)} >내용보기</button>
          </div>
          { this.state.isSubmit && !this.state.isAgree && <p className="Warning">개인정보 수집 및 이용에 동의해주세요.</p> }
          <div className="Row BtnArea">
            <button className="Submit" onClick={() => this._doSubmitConfirm()}>이벤트 참여하기</button>
          </div>
        </div>

        <div id="Layer" className={"LayerWrap " + (this.state.isPopupOpen ? "on" : "" )}>
          <div className="PopLayer">
            <div className="PopContainer">
              <p className="Title">개인정보 수집 및 이용안내</p>
              <p className="Sub">이벤트 참여 시 아래와 같이 고객님의 개인정보를 수집하고 있습니다.</p>
              <p className="Desc">
                <span>개인정보 수집범위 :</span> 이름, 주소, 연락처<br/>
                <span>개인정보 수집 및 이용목적 :</span> 이벤트 신청 확인 및 조회, 이벤트 경품 발송<br/>
                <span>개인정보 수집 및 보유기간 :</span> 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기하며 보유기간은 최대 3년을 넘기지 않는 것을 원칙으로 한다.
              </p>
            </div>
            <div className="BtnGroup">
              <button onClick={() => this.setState({ isPopupOpen: false })}>닫기</button>
            </div>
            <button className="BtnClose" onClick={() => this.setState({ isPopupOpen: false })}></button>
          </div>
        </div>

        <div id="Dialog" className={"LayerWrap " + (this.state.isDialogOpen ? "on" : "" )}>
          <div className="PopLayer">
            <div className="PopContainer">
              <p className="Msg" dangerouslySetInnerHTML={{ __html: this.state.dialogMsg }}></p>
            </div>
            <div className="BtnGroup">
              <button className="Ok" onClick={() => this.state.dialogType === "Confirm" ? this._doConfirm() : this._doCloseDialog()}>확인</button>
              <button className={"Cancel " + this.state.dialogType} onClick={() => this._doCloseDialog()}>취소</button>
            </div>
            <button className="BtnClose" onClick={() => this._doCloseDialog()}></button>
          </div>
        </div>

        {this.state.isPostcodeOpen && <div className="PostcodeLayer">
          <div className="PostcodeContainer">
            <button className="BtnClose" onClick={() => this.setState({ isPostcodeOpen: false })}></button>
            <DaumPostcodeEmbed className="Postcode" onComplete={this._handleComplete} />
          </div>
        </div> }

        {this.state.isLoading && <Loading />}
      </div> 
    )
  }
}

export default Event;