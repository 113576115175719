/*eslint-disable no-useless-escape*/

/**
 * 핸드폰번호 유효성 체크
 * @param {핸드폰번호} phoneNumber 
 */
export function isPhoneNumberValidation(phoneNumber) {
  var regex = /^(01[016789]{1}|070|02|0[3-9]{1}[0-9]{1})-[0-9]{3,4}-[0-9]{4}$/;
  return regex.test(parsePhoneNumber(phoneNumber.replace(/\-/gi, '')));
}

/**
 * 핸드폰번호 - 붙이기
 * @param {핸드폰 번호} phoneNumber 
 */
export function parsePhoneNumber(phoneNumber) {
  var newPhoneNumber = "";
  if (phoneNumber.length === '10') {
    newPhoneNumber = phoneNumber.substr(0, 3) + "-" + phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6, 4);
  } else {
    newPhoneNumber = phoneNumber.substr(0, 3) + "-" + phoneNumber.substr(3, 4) + "-" + phoneNumber.substr(7, 4);
  }
  return newPhoneNumber;
}

/**
 * 이메일 주소 유효성 검사
 * @param {이메일 주소} email 
 */
export function isEmailValidation(email) {
  var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
  return regex.test(email);
}

export function convertNewsTypeName(type) {
  if(type.toLowerCase() === "notice") {
    return "공지사항"
  }
  if(type.toLowerCase() === "media") {
    return "미디어"
  }
  if(type.toLowerCase() === "event") {
    return "이벤트"
  }
  return "";
}

/**
 * 엘리먼트 중앙정렬
 * @param {앨리먼트} ele 
 * @param {가로중앙정렬이 안맞는 경우 true} both 
 */
 export function getCenterPosStyle(ele, both) {
  var layerMarginLeft = 0;
  var layerMarginTop = 0;

  if(both) {
    var clientWidth = ele.clientWidth;
    var documentWidth = document.documentElement.offsetWidth;
  
    if (clientWidth < documentWidth ) {
      layerMarginLeft = clientWidth / 2;
    }
  }

  var clientHeight = ele.clientHeight;
  layerMarginTop = (clientHeight / 2);

  return {
    marginTop: '-' + layerMarginTop + 'px',
    marginLeft: '-' + layerMarginLeft + 'px',
  }
}

export function replaceNum(value) {
  let val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  console.log(val);
  return val
}

export function isHangul(value) {
  let regExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
  return regExp.test(value);
}