import React, { Component } from "react";
import * as Apis from "../components/Apis";
import * as Common from '../components/Common';
import Header from "./Header";
import Footer from "./Footer";
import Loading from '../components/Loading';

class Partner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopupOpen: false,
      isDialogOpen: false,
      isLoading: false,
      dialogType: "Alert",
      dialogMsg: "",
      partnerType: "",
      companyName: "",
      userName: "",
      tel: "",
      email: "",
      homepage: "",
      title: "",
      contents: "",
      attachFile: null,
    };
  }

  componentDidMount() {
  }

  _reload() {
    window.location.reload();
  }

  _doOpenDialog(msg, dialogType = "Alert") {
    this.setState({
      isDialogOpen: true,
      dialogMsg: msg,
      dialogType: dialogType
    });
  }

  _doCloseDialog() {
    this.setState({ isDialogOpen: false });

    let timer = setTimeout(() => {
      if(this.state.dialogType === "Complete" || this.state.dialogType === "Cancel") {
        this._reload();
      }
      clearTimeout(timer);
    }, 500);
  }

  _doOpenPopup(isOpen) {
    this.setState({ isPopupOpen: isOpen });
  }

  _doRequestConfirm() {
    this._doOpenDialog("제휴/상품입점/협력<br/>제안을 하시겠습니까?", "Confirm");
  }

  _doRequest() {
    let _partnerType = this.state.partnerType;
    if(!_partnerType) {
      this._doOpenDialog("제휴구분을<br/>선택해주세요.");
      return;
    }
    let _companyName = this.state.companyName;
    if(!_companyName) {
      this._doOpenDialog("회사명을<br/>입력해주세요.");
      return;
    }
    let _userName = this.state.userName;
    if(!_userName) {
      this._doOpenDialog("담당자명을<br/>입력해주세요.");
      return;
    }
    let _tel = this.state.tel;
    if(!_tel) {
      this._doOpenDialog("연락처를<br/>입력해주세요.");
      return;
    }
    if(!Common.isPhoneNumberValidation(_tel)) {
      this._doOpenDialog("연락처 형식에<br/>맞지 않습니다.");
      return;
    }

    let _email = this.state.email;
    if(!_email) {
      this._doOpenDialog("이메일을<br/>입력해주세요.");
      return;
    }
    if(_email && !Common.isEmailValidation(_email)) {
      this._doOpenDialog("이메일 형식에<br/>맞지 않습니다.");
      return;
    }

    let _title = this.state.title;
    if(!_title) {
      this._doOpenDialog("제목을<br/>입력해주세요.");
      return;
    }

    let _contents = this.state.contents;
    if(!_contents) {
      this._doOpenDialog("제안내용을<br/>입력해주세요.");
      return;
    }

    if(!this.state.isAgree) {
      this._doOpenDialog("개인정보 수집 및 이용에<br/>동의해주세요.");
      return;
    }

    this.setState({ isDialogOpen: false, isLoading: true });
    let formData = new FormData();
    formData.append("partnerType", _partnerType);
    formData.append("companyName", _companyName);
    formData.append("userName", _userName);
    formData.append("tel", _tel);
    formData.append("email", _email);
    formData.append("homepage", this.state.homepage);
    formData.append("title", _title);
    formData.append("contents", this.state.contents);
    formData.append("attachFile", this.state.attachFile);

    Apis.insertPartner(formData).then(({ loading, data: { result, resultCode, resultMessage } }) => {
      this.setState({ isLoading: false });
			if (resultCode === "S1000") {
        this._doOpenDialog("제안이 완료되었습니다.", "Complete");
			} else {
				this._doOpenDialog(resultMessage ? resultMessage : "문제가 발생하였습니다.<br/>다시 시도 해주세요.");
      }
    });
  }

  _doRequestSubmit() {
    
  }

  _doRequestCancel() {
    this._doOpenDialog("제휴/상품입점/협력 제안을<br/>취소하시겠습니까?", "Cancel");
  }

  render() {
    return (
      <div id="BrandComponent">
        <Header type="BRAND" section="" onClick={() => {}} />

        <div className="Container">
          <div id="Partner" className="Anchor" ref={ref => {this.NewsList = ref}}></div>
          <div className="Partner">
            <div className="SubContainer">
              <div className="TitleWrap">
                <p className="Title">제휴/상품입점/협력 제안</p>
                <p className="TitleSub">패스트카페는 협력사와의 다양한 제휴와 좋은 상품 제안을 소중히 생각합니다.</p>
                <p className="MOBILE">(*가 표시된 항목은 필수입력 항목입니다.)</p>
              </div>
              <div className="MainContent">
                <div className="Request">
                  <p className="MainTitle PC">신청자 정보 <span>(*가 표시된 항목은 필수입력 항목입니다.)</span></p>
                  <div className="Content">
                    <div className="Row">
                      <div className="Title">제휴구분 *</div>
                      <div className="Input Select">
                        <select onChange={e => this.setState({ partnerType: e.target.value })}>
                          <option value="">선택</option>
                          <option value="상품 입점 제안">상품 입점 제안</option>
                          <option value="사업 제안">사업 제안</option>
                          <option value="임차 제안">임차 제안</option>
                          <option value="광고 및 컨텐츠 제휴">광고 및 컨텐츠 제휴</option>
                          <option value="기타">기타</option>
                        </select>
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">회사명 *</div>
                      <div className="Input">
                        <input type="text" onKeyUp={e => this.setState({ companyName: e.target.value })} />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">담당자명 *</div>
                      <div className="Input">
                        <input type="text" onKeyUp={e => this.setState({ userName: e.target.value })} />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">연락처 *</div>
                      <div className="Input">
                        <input type="tel" maxLength="11" onKeyUp={e => this.setState({ tel: e.target.value })} />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">이메일 *</div>
                      <div className="Input">
                        <input type="text" onKeyUp={e => this.setState({ email: e.target.value })} />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">홈페이지</div>
                      <div className="Input">
                        <input type="text" onKeyUp={e => this.setState({ homepage: e.target.value })} />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">제목 *</div>
                      <div className="Input">
                        <input type="text" onKeyUp={e => this.setState({ title: e.target.value })} />
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Title">파일첨부</div>
                      <div className="Input File">
                        <span>{this.state.attachFile && this.state.attachFile.name}</span>
                        <label className="InputFile" htmlFor="File">업로드</label>
                        <input type="file" id="File" onChange={(e) => this.setState({ attachFile: e.target.files && e.target.files[0] })} />
                      </div>
                    </div>
                    <div className="Row Textarea">
                      <div className="Title Wide">제안내용/제품소개 *</div>
                      <textarea placeholder="기타 제안내용 및 재품소개를 작성해주세요."  onKeyUp={e => this.setState({ contents: e.target.value })}></textarea>
                    </div>
                    <div className="Row Checkbox MOBILE">
                      <label className="Label"> 개인정보 수집 및 이용에 동의합니다.
                        <input type="checkbox" name="isAgree" onChange={() => this.setState({ isAgree: !this.state.isAgree })} />
                        <span className="Checkmark"></span>
                      </label>
                      <button className="PrivacyLink" onClick={() => this._doOpenPopup(true)} >내용보기</button>
                    </div>
                    <div className="Row BtnArea MOBILE">
                      <button className="Request" onClick={() => this._doRequestConfirm()}>제안하기</button>
                    </div>
                  </div>
                </div>
                <div className="Privacy PC">
                  <p className="MainTitle">(필수) 개인정보 수집/이용 동의</p>
                  <div className="Content">
                    <div className="Row">
                      <p>
                        패스트카페는 제휴/입점 제안하는 기업 및 개인의 정보를 아래와 같은 목적으로 수집하며, 그 밖의 사항은 개인정보 처리 방침을 준수합니다.<br/><br/>
                        <span>수집 목적 :</span> 제휴, 제안에 따른 본인 확인 및 의사소통 경로 확보<br/>
                        <span>수집 정보 :</span> 회사명/ 담당자명/ 연락처/ 이메일<br/>
                        <span>이용 기간 :</span> 검토 완료 후 3개월
                      </p>
                    </div>
                    <div className="Row">
                      <label className="Label"> 개인정보 수집 및 이용에 동의합니다.
                        <input type="checkbox" name="isAgree" value="true" onChange={() => this.setState({ isAgree: !this.state.isAgree })} />
                        <span className="Checkmark"></span>
                      </label>
                    </div>
                    <div className="Row BtnArea">
                      <button className="Request" onClick={() => this._doRequestConfirm()}>제안</button>
                      <button className="Cancel" onClick={() => this._doRequestCancel()}>취소</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Anchor"></div>
          <Footer />
        </div>

        <div id="Layer" className={"MOBILE LayerWrap " + (this.state.isPopupOpen ? "on" : "" )}>
          <div className="PopLayer">
            <div className="PopContainer">
              <p className="Title">(필수) 개인정보 수집/이용 동의</p>
              <p className="Sub">패스트카페는 제휴/입점 제안하는 기업 및 개인의 정보를 아래와 같은 목적으로 수집하며, 그 밖의 사항은 개인정보 처리 방침을 준수합니다.</p>
              <p className="Desc">
                <span>수집 목적 :</span> 제휴, 제안에 따른 본인 확인 및 의사소통 경로 확보<br/>
                <span>수집 정보 :</span> 회사명/ 담당자명/ 연락처/ 이메일<br/>
                <span>이용 기간 :</span> 검토 완료 후 3개월
              </p>
            </div>
            <button className="BtnClose" onClick={() => this.setState({ isPopupOpen: false })}></button>
          </div>
        </div>

        <div id="Dialog" className={"LayerWrap " + (this.state.isDialogOpen ? "on" : "" )}>
          <div className="PopLayer">
            <div className="PopContainer">
              <p className="Msg" dangerouslySetInnerHTML={{ __html: this.state.dialogMsg }}></p>
            </div>
            <div className="BtnGroup">
              <button className="Ok" onClick={() => this.state.dialogType === "Confirm" ? this._doRequest() : this._doCloseDialog()}>확인</button>
              <button className={"Cancel " + this.state.dialogType} onClick={() => this._doCloseDialog()}>취소</button>
            </div>
            <button className="BtnClose" onClick={() => this._doCloseDialog()}></button>
          </div>
        </div>

        {this.state.isLoading && <Loading />}
      </div> 
    )
  }
}

export default Partner;