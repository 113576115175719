import React, { Component } from "react";
import { Link } from 'react-router-dom';
import * as Consts from "../components/Consts";
import * as Apis from "../components/Apis";
import Header from "./Header";
import Footer from "./Footer";

class EventDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entity: null
    };
  }

  componentDidMount() {
    var pathnames = this.props.location.pathname.split('/');
    this._getEvent( pathnames[pathnames.length - 1] );
  }

  _getEvent(id) {
    Apis.getEvent(id).then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        this.setState({ entity: result.entity });
			} else {
				console.log(resultMessage);
      }
    });
  }

  render() {
    return (
      <div id="BrandComponent">
        <Header type="BRAND" section="News" onClick={() => {}} />

        <div className="Container">
          <div id="NewsList" className="Anchor" ref={ref => {this.NewsList = ref}}></div>
          <div className="NewsList">
            <div className="SubContainer">
              <div className="TitleWrap">
                <p className="Title">NEWS</p>
                <ul>
                  <li>
                    <Link to="/news/notice" title="공지사항">공지사항</Link>
                  </li>
                  <li>
                    <Link to="/news/media" title="미디어">미디어</Link>
                  </li>
                  <li className="Select">
                    <Link to="/news/event" title="이벤트">이벤트</Link>
                  </li>
                </ul>
              </div>
              <div className="DetailView">
                <div className="DetailWrap">
                  <div className="Title">
                    <div className="Left">
                      <p className="Title">{ this.state.entity ? this.state.entity.title : "" }</p>
                    </div>
                    <div className="Right">
                      <p className="Date">{ this.state.entity ? this.state.entity.regdate : "" }</p>
                    </div>
                  </div>
                  <div className="Content" dangerouslySetInnerHTML={{ __html: this.state.entity ? this.state.entity.contents : "" }}>
                  </div>
                  { this.state.entity && this.state.entity.attachFileUrl ? 
                    <div className="Attach">
                      첨부파일 : <a href={Consts.CLOSE_API_ETC_URL + "/fileDownload?fileUrl=" + this.state.entity.attachFileUrl + "&fileName=" + this.state.entity.originFilename}>{ this.state.entity.originFilename }</a>
                    </div> : ""
                  }
                </div>
                <div className="BtnWrap">
                  <Link to="/news/event"><button className="List">목록</button></Link>
                </div>
              </div>  
            </div>
          </div>

          <div className="Anchor"></div>
          <Footer />
        </div>
      </div> 
    )
  }
}

export default EventDetail;