import React, { Fragment, Component } from "react";
import { Link } from 'react-router-dom';

class FooterNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <section id="Footer" className="page-section p-0 py-3 bg-gray-200 text-keep">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6 col-xl-7 text-gray-600 fs-5">
              <p className="p-0 m-0">㈜피투피시스템즈 | 대표 김영권 | 110-81-53273</p>
              <p className="p-0 m-0">서울특별시 서대문구 연세로 10-1 즐거운빌딩 9층</p>
              <p className="p-0 mx-0 my-2">대표전화 : 1661-0227</p>
              <p className="p-0 m-0">COPYRIGHT ⓒ 2022 P2PSYSTEMS. ALL RIGHTS RESERVED.</p>
            </div>
            <hr className="d-block d-lg-none divider"></hr>
            <div className="col-12 col-lg-6 col-xl-5 d-flex flex-column align-content-evenly">
              <a href="/partner" className="text-gray-600 fs-3 p-0 m-0">제휴/협력 제안</a>

              <div className="row mt-2 fs-5">
                <a className="col-6 d-flex align-items-center" href="https://blog.naver.com/fastcafe2021/" target="_blank" rel="noopener noreferrer">
                  <button className="Naver"></button>
                  <span className="mx-2 text-gray-600">네이버 블로그</span>
                </a>
                <a className="col-6 d-flex align-items-center" href="https://youtu.be/watch?v=KMW8diPfsAU" target="_blank" rel="noopener noreferrer">
                  <button className="Youtube"></button>
                  <span className="mx-2 text-gray-600">유튜브</span>
                </a>
              </div>
              <div className="row mt-2 fs-5">
                <a className="col-6 d-flex align-items-center" href="https://www.instagram.com/fastcafe_business/?igshid=p3q5advkbm81" target="_blank" rel="noopener noreferrer">
                  <button className="Instagram"></button>
                  <span className="mx-2 text-gray-600">인스타그램(창업)</span>
                </a>
                <a className="col-6 d-flex align-items-center" href="https://www.instagram.com/explore/tags/%ED%8C%A8%EC%8A%A4%ED%8A%B8%EC%B9%B4%ED%8E%98/" target="_blank" rel="noopener noreferrer">
                  <button className="Instagram"></button>
                  <span className="mx-2 text-gray-600">인스타그램</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default FooterNew;
