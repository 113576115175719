import React, { Component } from "react";
import { Link } from 'react-router-dom';
import * as Apis from "../components/Apis";
import Header from "./Header";
import Footer from "./Footer";
class MediaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      size: 10,
      totalCnt: 0,
      entities: [],
      isMore: false
    };
  }

  componentDidMount() {
    this._listMedia(1);
  }

  _addList() {
    this._listMedia(this.state.page + 1);
  }

  _listMedia(page) {
    Apis.listMedia(page).then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        let _entities = ( page === 1 ? result.entities : [...this.state.entities, ...result.entities] );
        this.setState({
          page: page,
          totalCnt: result.totalCnt,
          entities: _entities,
          isMore: result.totalCnt !== _entities.length
        });
			} else {
				console.log(resultMessage);
      }
    });
  }

  render() {
    return (
      <div id="BrandComponent">
        <Header type="BRAND" section="News" onClick={() => {}} />

        <div className="Container">
          <div id="NewsList" className="Anchor" ref={ref => {this.NewsList = ref}}></div>
          <div className="NewsList">
            <div className="SubContainer">
              <div className="TitleWrap">
                <p className="Title">NEWS</p>
                <ul>
                  <li>
                    <Link to="/news/notice" title="공지사항">공지사항</Link>
                  </li>
                  <li className="Select">
                    <Link to="/news/media" title="미디어">미디어</Link>
                  </li>
                  <li>
                    <Link to="/news/event" title="이벤트">이벤트</Link>
                  </li>
                </ul>
              </div>
              <div className="ListView">
                <div className="ListWrap">
                  { this.state.entities ? 
                    <ul>
                      { this.state.entities.map((entity, idx) => (
                        <li key={idx}>
                          <a href={entity.linkUrl ? entity.linkUrl : ("/news/media/" + entity.id)} target={ entity.linkUrl ? "_blank" : "_self" }>
                            <span className="Title">{entity.title}</span>
                            <span className="Date">{entity.regdate}</span>
                          </a>
                        </li>
                      ))}
                    </ul> :
                    <p className="Nodata">등록된 게시물이 없습니다.</p>
                  }
                </div>
                { this.state.isMore ? 
                  <div className="BtnWrap">
                    <button className="More" onClick={() => this._addList()}>더보기 +</button>
                  </div> : ""
                }
              </div>
            </div>
          </div>

          <div className="Anchor"></div>
          <Footer />
        </div>
      </div> 
    )
  }
}

export default MediaList;