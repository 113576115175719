import React, { Fragment, Component } from "react";
import { HashLink } from 'react-router-hash-link';
import Slider from "react-slick";

const settings = {
  arrow: false,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 3000,
  inifinite: false,
  useCSS: true,
  touchMove: true,
  pauseOnHover: true,
};

class LayerPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      slideCurrIdx: 0,
      slideNextIdx: 0,
    };
  }

  componentDidMount() {
    if(document.cookie.indexOf(`todayCookie=done`) >= 0) {
      let el = document.getElementById("Popup");
      el.remove();
    } else {
      this.setState({ isOpen: true });
    }
  }

  _beforeChange(before, next) {
    this.setState({ slideNextIdx: next });
  }

  _afterChange(idx) {
    this.setState({ slideCurrIdx: idx });
  }

  close = (isExpire) => {
    if(isExpire) {
      var todayDate = new Date();
      todayDate.setDate( todayDate.getDate() + 1 );
      document.cookie = "todayCookie=" + escape( "done" ) + "; path=/; expires=" + todayDate.toGMTString() + ";"
    }

    let el = document.getElementById("Popup");
    el.remove();
  }

  renderImgs() {
    return (
      <Fragment>
        <ul className="NavContainer MOBILE">
        {
            this.props.datas.map((d, i) => 
            <li key={i}>
              <button className={ this.state.slideNextIdx === i ? "active" : "" } onClick={() => this.slide.slickGoTo(i)}></button>
            </li>
          )
        }
        </ul>
        { this.props.datas &&
          <Slider ref={ref => this.slide = ref} afterChange={(idx) => this._afterChange(idx)} beforeChange={(current, next) => this._beforeChange(current, next)} className="PopupSlide MOBILE" {...settings}>
            {
              this.props.datas.map((d, i) =>
                d.linkUrl ? 
                <HashLink key={i} to={d.linkUrl} target={"_" + d.target} rel="noopener noreferrer">
                  <img src={d.mobileImgUrl} alt="" />
                </HashLink> : <img key={i} src={d.mobileImgUrl} alt="" />
              )
            }
          </Slider>
        }
        { this.props.datas &&
          <div className="Image PC">
            {
              this.props.datas.map((d, i) =>
                d.linkUrl ? 
                <HashLink key={i} to={d.linkUrl} target={"_" + d.target} rel="noopener noreferrer">
                  <img src={d.pcImgUrl} alt="" />
                </HashLink>
                : <img key={i} src={d.pcImgUrl} alt="" />
              )
            }
          </div>
        }
      </Fragment>
    )
  }

  render() {
    return (
      <Fragment>
        <div id="Popup" className={ this.state.isOpen ? "Show" : "Hide" }>
          <div className="Bg MOBILE"></div>
          <div className="ImageWrap">
            { this.props.datas && this.renderImgs() }
            <div className="Bottom">
              <button onClick={() => this.close(true)}>오늘 하루 보지 않기</button>
              <button onClick={() => this.close(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default LayerPopup;
