/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef, useCallback } from "react";
import { useInView } from 'react-intersection-observer';
import Slider from "react-slick";
import "moment/locale/ko";
import { isMobile } from "react-device-detect";
import * as Apis from "../components/Apis";
import * as Common from '../components/Common';
import Footer from "./FooterNew";
import Loading from '../components/Loading';
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

import IconPlus from "../images/franchise/icon_plus.png";

import HomePC from "../images/franchise/home_pc.png";
import HomeM from "../images/franchise/home_m.png";

import NewBg from "../images/brand/bg-news_new.png";
import NavLogo from "../images/franchise/navbar-logo.svg";
import ArrowLeft from "../images/brand/arrow-top-left.png";
import ArrowRight from "../images/brand/arrow-top-right.png";

import Why1 from "../images/franchise/why/why_1.jpg";
import Why2 from "../images/franchise/why/why_2.jpg";
import Why3 from "../images/franchise/why/why_3.jpg";

import Interior1 from "../images/franchise/interior/1.jpg";
import Interior2 from "../images/franchise/interior/2.jpg";
import Interior3 from "../images/franchise/interior/3.jpg";
import Interior4 from "../images/franchise/interior/4.jpg";
import Interior5 from "../images/franchise/interior/5.jpg";
import Interior6 from "../images/franchise/interior/6.jpg";
import Interior7 from "../images/franchise/interior/7.png";
import Interior8 from "../images/franchise/interior/8.png";
import Interior9 from "../images/franchise/interior/9.png";

import CompetitiveBean from "../images/franchise/competitive/BEAN.jpg";
import CompetitiveFritz from "../images/franchise/competitive/FRITZ.jpg";
import CompetitiveHebe from "../images/franchise/competitive/HEBE.jpg";
import CompetitiveMomos from "../images/franchise/competitive/MOMOS.jpg";
import CompetitiveYm from "../images/franchise/competitive/YM.jpg";

import Partners1 from "../images/franchise/partners/r_1.jpg";
import Partners2 from "../images/franchise/partners/r_2.jpg";
import Partners3 from "../images/franchise/partners/r_3.jpg";
import Partners4 from "../images/franchise/partners/r_4.jpg";
import Partners5 from "../images/franchise/partners/r_5.jpg";
import Partners6 from "../images/franchise/partners/r_6.jpg";
import Partners7 from "../images/franchise/partners/r_7.jpg";
import Partners8 from "../images/franchise/partners/r_8.jpg";
import Partners9 from "../images/franchise/partners/r_9.jpg";
import Partners10 from "../images/franchise/partners/r_10.jpg";
import Partners11 from "../images/franchise/partners/r_11.jpg";
import Partners12 from "../images/franchise/partners/r_12.jpg";
import Partners13 from "../images/franchise/partners/r_13.jpg";
import Partners14 from "../images/franchise/partners/r_14.jpg";

import AboutIcon1 from "../images/franchise/about/icon_1.svg";
import AboutIcon2 from "../images/franchise/about/icon_2.svg";
import AboutIcon3 from "../images/franchise/about/icon_3.svg";
import AboutIcon4 from "../images/franchise/about/icon_4.svg";

import AboutPortfolio1 from "../images/franchise/about/1.jpg";
import AboutPortfolio2 from "../images/franchise/about/2.jpg";
import AboutPortfolio3 from "../images/franchise/about/3.jpg";
import AboutPortfolio4 from "../images/franchise/about/4.jpg";
import AboutPortfolio5 from "../images/franchise/about/5.jpg";
import AboutPortfolio6 from "../images/franchise/about/6.jpg";

import With1 from "../images/franchise/with/with_1.jpg";
import With2 from "../images/franchise/with/with_2.jpg";

import Moim1 from "../images/franchise/moim/1.png";
import Moim2 from "../images/franchise/moim/2.png";
import Moim3 from "../images/franchise/moim/3.jpg";
import Moim4 from "../images/franchise/moim/4.jpg";
import Moim5 from "../images/franchise/moim/5.jpg";
import Moim6 from "../images/franchise/moim/6.jpg";
import Moim7 from "../images/franchise/moim/7.jpg";

import ProcedureStep1 from "../images/franchise/procedure/1.png";
import ProcedureStep2 from "../images/franchise/procedure/2.png";
import ProcedureStep3 from "../images/franchise/procedure/3.png";
import ProcedureStep4 from "../images/franchise/procedure/4.png";
import ProcedureStep5 from "../images/franchise/procedure/5.png";
import ProcedureStep6 from "../images/franchise/procedure/6.png";
import ProcedureStep7 from "../images/franchise/procedure/7.png";
import ProcedureStep8 from "../images/franchise/procedure/8.png";

import Machine1 from "../images/franchise/machine/1.png";
import Machine2 from "../images/franchise/machine/2.png";
import Machine3 from "../images/franchise/machine/3.png";

import ConsultingTel from "../images/franchise/consulting/tel.png";
import ConsultingKakao from "../images/franchise/consulting/kakao.png";
import ConsultingTop from "../images/franchise/consulting/top.png";

import LayerPopup from "./LayerPopup";

const settingsForPartners = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 6,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true
      }
    },
  ],
};

const settingsForCompetitive1 = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
  ],
};

const settingsForAbout = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
  ],
};

const settingsForOpenStore = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
  ],
};

export default function Franchise() {
  const competitive1SlideRef = useRef();
  const aboutSlideRef = useRef();
  const openStoreSlideRef = useRef();

  const { ref: whyRef1, inView: isWhyVisible1 } = useInView({ threshold: 0.5 });
  const { ref: whyRef2, inView: isWhyVisible2 } = useInView({ threshold: 0.4 });
  const { ref: whyRef3, inView: isWhyVisible3 } = useInView({ threshold: 0.7 });
  const { ref: whyRef4, inView: isWhyVisible4 } = useInView({ threshold: 0.5 });

  const { ref: coffeeRef1, inView: isCoffeeVisible1 } = useInView({ threshold: 0.5 });
  const { ref: coffeeRef2, inView: isCoffeeVisible2 } = useInView({ threshold: 0.4 });
  const { ref: coffeeRef3, inView: isCoffeeVisible3 } = useInView({ threshold: 0.7 });
  const { ref: coffeeRef4, inView: isCoffeeVisible4 } = useInView({ threshold: 0.5 });

  const { ref: reviewerRef1, inView: isReviewerVisible1 } = useInView({ threshold: 0.6 });
  const { ref: reviewerRef2, inView: isReviewerVisible2 } = useInView({ threshold: 0.5 });

  const { ref: reasonRef1, inView: isReasonVisible1 } = useInView({ threshold: 0.3 });
  const { ref: reasonRef2, inView: isReasonVisible2 } = useInView({ threshold: 0.3 });
  const { ref: reasonRef3, inView: isReasonVisible3 } = useInView({ threshold: 0.5 });
  const { ref: reasonRef4, inView: isReasonVisible4 } = useInView({ threshold: 0.5 });
  const { ref: reasonRef5, inView: isReasonVisible5 } = useInView({ threshold: 0.5 });
  const { ref: reasonRef6, inView: isReasonVisible6 } = useInView({ threshold: 0.5 });
  const { ref: reasonRef7, inView: isReasonVisible7 } = useInView({ threshold: 0.5 });
  const { ref: reasonRef8, inView: isReasonVisible8 } = useInView({ threshold: 0.5 });
  const { ref: reasonRef9, inView: isReasonVisible9 } = useInView({ threshold: 0.5 });

  const { ref: costRef1, inView: isCostVisible1 } = useInView({ threshold: 0.5 });
  const { ref: costRef2, inView: isCostVisible2 } = useInView({ threshold: 0.4 });
  const { ref: costRef3, inView: isCostVisible3 } = useInView({ threshold: 0.4 });
  const { ref: costRef4, inView: isCostVisible4 } = useInView({ threshold: 0.4 });

  const { ref: interiorRef1, inView: isInteriorVisible1 } = useInView({ threshold: 0.5 });
  const { ref: interiorRef2, inView: isInteriorVisible2 } = useInView({ threshold: 0.3 });

  const { ref: salesRef1, inView: isSalesVisible1 } = useInView({ threshold: 0.5 });
  const { ref: salesRef2, inView: isSalesVisible2 } = useInView({ threshold: 0.4 });
  const { ref: salesRef3, inView: isSalesVisible3 } = useInView({ threshold: 0.7 });

  const { ref: competitive2Ref1, inView: isCompetitive2Visible1 } = useInView({ threshold: 0.3 });
  const { ref: competitive2Ref2, inView: isCompetitive2Visible2 } = useInView({ threshold: 0.4 });
  const { ref: competitive2Ref3, inView: isCompetitive2Visible3 } = useInView({ threshold: 0.5 });
  const { ref: competitive2Ref4, inView: isCompetitive2Visible4 } = useInView({ threshold: 0.6 });
  const { ref: competitive2Ref5, inView: isCompetitive2Visible5 } = useInView({ threshold: 0.5 });
  const { ref: competitive2Ref6, inView: isCompetitive2Visible6 } = useInView({ threshold: 0.6 });
  const { ref: competitive2Ref7, inView: isCompetitive2Visible7 } = useInView({ threshold: 0.6 });
  const { ref: competitive2Ref8, inView: isCompetitive2Visible8 } = useInView({ threshold: 0.6 });

  const { ref: competitive3Ref1, inView: isCompetitive3Visible1 } = useInView({ threshold: 0.3 });
  const { ref: competitive3Ref2, inView: isCompetitive3Visible2 } = useInView({ threshold: 0.4 });
  const { ref: competitive3Ref3, inView: isCompetitive3Visible3 } = useInView({ threshold: 0.5 });
  const { ref: competitive3Ref4, inView: isCompetitive3Visible4 } = useInView({ threshold: 0.5 });
  const { ref: competitive3Ref5, inView: isCompetitive3Visible5 } = useInView({ threshold: 0.7 });
  const { ref: competitive3Ref6, inView: isCompetitive3Visible6 } = useInView({ threshold: 0.6 });
  const { ref: competitive3Ref7, inView: isCompetitive3Visible7 } = useInView({ threshold: 0.5 });

  const { ref: aboutRef1, inView: isAboutVisible1 } = useInView({ threshold: 0.3 });
  const { ref: aboutRef2, inView: isAboutVisible2 } = useInView({ threshold: 0.4 });
  const { ref: aboutRef3, inView: isAboutVisible3 } = useInView({ threshold: 0.5 });
  const { ref: aboutRef4, inView: isAboutVisible4 } = useInView({ threshold: 0.3 });
  const { ref: aboutRef5, inView: isAboutVisible5 } = useInView({ threshold: 0.4 });
  const { ref: aboutRef6, inView: isAboutVisible6 } = useInView({ threshold: 0.5 });
  const { ref: aboutRef7, inView: isAboutVisible7 } = useInView({ threshold: 0.6 });
  const { ref: aboutRef8, inView: isAboutVisible8 } = useInView({ threshold: 0.6 });

  const { ref: procedureRef1, inView: isProcedureVisible1 } = useInView({ threshold: 0.3 });
  const { ref: procedureRef2, inView: isProcedureVisible2 } = useInView({ threshold: 0.4 });
  const { ref: procedureRef3, inView: isProcedureVisible3 } = useInView({ threshold: 0.3 });
  const { ref: procedureRef4, inView: isProcedureVisible4 } = useInView({ threshold: 0.35 });
  const { ref: procedureRef5, inView: isProcedureVisible5 } = useInView({ threshold: 0.4 });
  const { ref: procedureRef6, inView: isProcedureVisible6 } = useInView({ threshold: 0.45 });
  const { ref: procedureRef7, inView: isProcedureVisible7 } = useInView({ threshold: 0.5 });
  const { ref: procedureRef8, inView: isProcedureVisible8 } = useInView({ threshold: 0.55 });
  const { ref: procedureRef9, inView: isProcedureVisible9 } = useInView({ threshold: 0.6 });
  const { ref: procedureRef10, inView: isProcedureVisible10 } = useInView({ threshold: 0.65 });

  const [ isPopupOpen, setIsPopupOpen ] = useState(false);
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isReviewerDialogOpen, setIsReviewerDialogOpen ] = useState(false);
  const [ reviewerDetailEntity, setReviewerDetailEntity ] = useState(null);
  const [ isItemDialogOpen, setIsItemDialogOpen ] = useState(false);
  const [ itemDetailEntity, setItemDetailEntity ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ dialogType, setDialogType ] = useState("Alert");
  const [ dialogSubType, setDialogSubType ] = useState("");
  const [ dialogMsg, setDialogMsg ] = useState("");
  const [ isOpenMachine1, setIsOpenMachine1 ] = useState(false);
  const [ isOpenMachine2, setIsOpenMachine2 ] = useState(false);
  const [ isOpenMachine3, setIsOpenMachine3 ] = useState(false);
  const [ isAgree, setIsAgree ] = useState(false);
  const [ competitive1CurrIdx, setCompetitive1CurrIdx ] = useState(0);
  const [ competitive1NextIdx, setCompetitive1NextIdx ] = useState(0);
  const [ aboutCurrIdx, setAboutCurrIdx ] = useState(0);
  const [ aboutNextIdx, setAboutNextIdx ] = useState(0);
  const [ openStoreCurrIdx, setOpenStoreCurrIdx ] = useState(0);
  const [ openStoreNextIdx, setOpenStoreNextIdx ] = useState(0);
  const [ userName, setUserName ] = useState("");
  const [ userTel, setUserTel ] = useState("");
  const [ isMobile, setIsMobile ] = useState(false);
  const [ banners, setBanners ] = useState([{ mobileImgUrl: HomeM, pcImgUrl: HomePC, linkUrl: null }]);
  const [ popups, setPopups ] = useState(null);
  const [ goToSalesSlide, setGoToSalesSlide ] = useState(0);
  const [ offsetRadius, setOffsetRadius ] = useState(4);
  const [ showNavigation, setShowNavigation ] = useState(false);
  const [ sales, setSales ] = useState([]);
  const [ items, setItems ] = useState([]);
  const [ itemPage, setItemPage ] = useState(1);
  const [ itemSize, setItemSize ] = useState(8);
  const [ itemType, setItemType ] = useState("");
  const [ isItemLastPage, setIsItemLastPage ] = useState(false);
  const [ reviewers, setReviewers ] = useState([]);
  const [ openStores, setOpenStores ] = useState(null);
  const [ newsList, setNewsList ] = useState(null);
  const [ isShowInquiryMenuBar, setIsShowInquiryMenuBar ] = useState(false);
  const [ interiors, setInteriors ] = useState([ Interior1, Interior2, Interior3, Interior4, Interior5, Interior6, Interior7, Interior8, Interior9 ]);
  const [ partners, setPartners ] = useState([ Partners1, Partners2, Partners3, Partners4, Partners5, Partners6, Partners7, Partners8, Partners9, Partners10, Partners11, Partners12, Partners13, Partners14 ]);
  const [ userName2, setUserName2 ] = useState("");
  const [ userTel2, setUserTel2 ] = useState("");
  const [ userWantArea, setUserWantArea ] = useState("");
  const [ wantType, setWantType ] = useState("");
  const [ isBuildingOwner, setIsBuildingOwner ] = useState(0);
  const [ ask, setAsk ] = useState("");
  const [ isAgree2, setIsAgree2 ] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("html");
    document.getElementsByTagName("body")[0].classList.add("body");
    document.getElementsByTagName("body")[0].classList.add("pb-5");

    let _isMobile = document.getElementsByTagName("body")[0].offsetWidth < 992;

    let mainNav = document.getElementById('mainNav');
    document.getElementsByTagName("body")[0].style.paddingTop = _isMobile ? mainNav.offsetHeight + "px" : "0px";
    let consultingBottom = document.getElementById('ConsultingBottom');
    document.getElementsByTagName("body")[0].style.paddingBottom = consultingBottom.offsetHeight + "px";
    window.addEventListener("resize", () => {
      let mainNav = document.getElementById('mainNav');
      document.getElementsByTagName("body")[0].style.paddingTop = _isMobile ? mainNav.offsetHeight + "px" : "0px";
      let consultingBottom = document.getElementById('ConsultingBottom');
      document.getElementsByTagName("body")[0].style.paddingBottom = consultingBottom.offsetHeight + "px";
    });

    setIsMobile(_isMobile);

    _listBanner();

    _listReviewers();

    _listSales();

    _listItem(true, "");

    _listOpenStore();

    _listNews();
  }, []);

  // 시간 경과 체크를 위한 useEffect
  useEffect(() => {
    const timer = setTimeout(() => {
      setGoToSalesSlide(goToSalesSlide + 1);
    }, 3000);
    return () => clearTimeout(timer);
  }, [goToSalesSlide]);

  function _goToSalesSlide(value) {
    setGoToSalesSlide(goToSalesSlide + value);
  }

  function _beforeChangeForCompetitive1(before, next) {
    setCompetitive1NextIdx(next);
  }

  function _afterChangeForCompetitive1(idx) {
    setCompetitive1CurrIdx(idx);
  }

  function _beforeChangeForAbout(before, next) {
    setAboutNextIdx(next);
  }

  function _afterChangeForAbout(idx) {
    setAboutCurrIdx(idx);
  }

  function _beforeChangeForOpenStore(before, next) {
    setOpenStoreNextIdx(next);
  }

  function _afterChangeForOpenStore(idx) {
    setOpenStoreCurrIdx(idx);
  }

  function _reload() {
    window.location.reload();
  }

  /**
   * 후기 리스트
   */
   function _listReviewers() {
    Apis.listReview().then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        setReviewers(result.entities);
			} else {
				console.log(resultMessage);
      }
    });
  }

  function _dialogReviewerOpen(entity) {
    let reviewerDetailEntity = {
      title: entity.storeName,
      detail: entity.detail_contents
    };

    setIsReviewerDialogOpen(true);
    setReviewerDetailEntity(reviewerDetailEntity);

    document.body.style.overflowY = "hidden";
  }

  function _dialogReviewerClose() {
    setIsReviewerDialogOpen(false);
    setReviewerDetailEntity(null);

    document.body.style.overflowY = "auto";
  }

  /**
   * 메뉴 리스트
   * @param {*} isInit 
   * @param {*} type 
   */
   function _listItem(isInit, type) {
    if(isInit) setItems([]);
    let _itemPage = isInit ? 1 : parseInt(itemPage) + 1;

    Apis.listItem(type, _itemPage, itemSize).then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        let _totalItems = result.totalCnt;
        let _itemsLen = result.entities.length;
        if(!isInit) _itemsLen += items.length;
        let idx = itemSize * (_itemPage - 1);
        let interval = setInterval(function(){
          if(idx === _itemsLen){
              clearInterval(interval);
          } else {
            let eleItemParent = document.getElementById("Item");
            let eleItems = eleItemParent.querySelectorAll(".item");
            eleItems[idx].classList.add("show");
            idx++;
          }
        }, 100);

        setItemType(type);
        setItemPage(_itemPage);
        setIsItemLastPage(_totalItems <= _itemPage * itemSize);
        setItems(isInit ? result.entities : items.concat(result.entities));
			} else {
				console.log(resultMessage);
      }
    });
  }

  function _dialogItemOpen(entity) {
    setIsItemDialogOpen(true);
    setItemDetailEntity(entity.imgDetailUrl);

    document.body.style.overflowY = "hidden";
  }

  function _dialogItemClose() {
    setIsItemDialogOpen(false);
    setItemDetailEntity(null);

    document.body.style.overflowY = "auto";
  }

  /**
   * 매출(투명한 매출 공개)
   */
   function _listSales() {
    Apis.listSales().then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        let sales = [];
        if(result.entities && result.entities.length > 0) {
          for( let idx in result.entities ) {
            let entity = result.entities[idx];
            sales.push({
              key: idx,
              content: <div className="CardItem">
                <div className="CardItemTop">
                  <img src={entity.imgUrl} alt={entity.storeName} />
                </div>
                <div className="CardItemBottom">
                  <p>{entity.storeName}</p>
                  <p>월 매출 <CountUp separator="," start={entity.sale} end={entity.sale} /> 원</p>
                </div>
              </div>
            });
          }

          sales.map((slide, index) => {
            return { ...slide, onClick: () => {
              setGoToSalesSlide(index);
            } };
          });

          setSales(sales);
        }
        
			} else {
				console.log(resultMessage);
      }
    });
  }

  /**
   * 실시간 가맹 현황
   */
   function _listOpenStore() {
    Apis.listOpen().then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        setOpenStores(result.entities);
			} else {
				console.log(resultMessage);
      }
    });
  }

  /**
   * 뉴스&이벤트
   */
   function _listNews() {
    Apis.listMain().then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        setNewsList(result.entities);
			} else {
				console.log(resultMessage);
      }
    });
  };

  function _doOpenDialog(msg, dialogType = "Alert", dialogSubType = "") {
    setIsDialogOpen(true);
    setDialogMsg(msg);
    setDialogType(dialogType);
    setDialogSubType(dialogSubType);
  }

  function _doCloseDialog() {
    setIsDialogOpen(false);

    let timer = setTimeout(() => {
      if(dialogType === "Complete" || dialogType === "Cancel") {
        _reload();
      }
      clearTimeout(timer);
    }, 500);
  }

  function _doRequestConfirm() {
    window.gtag_franchise('패스트카페 문의하기');

    _doOpenDialog("창업문의를 하시겠습니까?", "Confirm", "Request");
  }

  function _doSubmit(type) {
    if(type === "Request") {
      _doRequest();
    } else if(type === "Consulting") {
      _doConsulting();
    }
  }

  function _doRequest() {
    let _userName = userName;
    if(!_userName) {
      _doOpenDialog("이름을 입력해주세요.");
      return;
    }

    let _userTel = userTel;
    if(!_userTel) {
      _doOpenDialog("연락처를<br/>입력해주세요.");
      return;
    }
    if(!Common.isPhoneNumberValidation(_userTel)) {
      _doOpenDialog("휴대전화번호 형식에<br/>맞지 않습니다.");
      return;
    }
    let _userTel1 = _userTel.substring(0, 3);
		let _userTel2 = _userTel.substring(3, _userTel.length === 10 ? 6 : 7);
		let _userTel3 = _userTel.substring(_userTel.length === 10 ? 6 : 7, _userTel.length === 10 ? 10 : 11);

    if(!isAgree) {
      _doOpenDialog("개인정보 수집 및 이용에<br/>동의해주세요.");
      return;
    }

    setIsDialogOpen(false);
    setIsLoading(true);
    Apis.insertFranchise("FASTCAFE", _userName, _userTel1, _userTel2, _userTel3, "", "", "", "", "").then(({ loading, data: { result, resultCode, resultMessage } }) => {
      setIsLoading(false);
			if (resultCode === "S1000") {
        window.wcsLog();  // 네이버 검색 전환코드 실행
        window.gtag_report_conversion(); // gtag
        window.gtag_report_conversion_mirae(); // gtag
        window.gtag_mirae(); // gtag
        window.kakaoMoment();
        _doOpenDialog("신청이 완료되었습니다.", "Complete");
			} else {
				_doOpenDialog("문제가 발생하였습니다.<br/>다시 시도 해주세요.");
      }
    });
  }

  function _listBanner() {
    Apis.listBanner("", "franchise", 999).then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        let _banners = [];
        let _popups = [];
        if(result.entities.length !== 0 && result.entities !== null) {
          for(let idx in result.entities) {
            let _entity = result.entities[idx];
            if(_entity.type === "banner") {
              _banners.push(_entity);
            } else if(_entity.type === "popup") {
              _popups.push(_entity);
            }
          }

          setBanners(_banners);
          setPopups(_popups);
        }
			} else {
				console.log(resultMessage);
      }
    });
  }

  function _bannerClick(id) {
    Apis.updateBannerViewCount(id).then(({ loading, data: { result, resultCode, resultMessage } }) => {
    });
  }

  function _doInquiryWithGtag(eventName) {
    window.gtag_franchise('패스트카페 ' + eventName);
    if(eventName.indexOf('전화문의') > -1) {
      document.location.href = "tel:1661-0227";
    } else if(eventName === '카톡문의') {
      window.open('http://pf.kakao.com/_cGips/chat', '_blank')
    }
  }

  function _doConsultingConfirm() {
    window.gtag_franchise('패스트카페 상담신청');

    _doOpenDialog("상담신청을 하시겠습니까?", "Confirm", "Consulting");
  }

  function _doConsulting() {
    let _userName = userName2;
    if(!_userName) {
      _doOpenDialog("성명을 입력해주세요.");
      return;
    }

    let _userTel = userTel2;
    if(!_userTel) {
      _doOpenDialog("휴대전화번호를<br/>입력해주세요.");
      return;
    }
    if(!Common.isPhoneNumberValidation(_userTel)) {
      _doOpenDialog("휴대전화번호 형식에<br/>맞지 않습니다.");
      return;
    }
    let _userTel1 = _userTel.substring(0, 3);
		let _userTel2 = _userTel.substring(3, _userTel.length === 10 ? 6 : 7);
		let _userTel3 = _userTel.substring(_userTel.length === 10 ? 6 : 7, _userTel.length === 10 ? 10 : 11);

    let _ask = "";
    if(wantType) {
      _ask = "희망창업형태 : " + wantType + "<br/>";
    }
    _ask += ask;

    if(!isAgree2) {
      _doOpenDialog("개인정보 수집 및 이용에<br/>동의해주세요.");
      return;
    }

    setIsDialogOpen(false);
    setIsLoading(true);
    Apis.insertFranchise("FASTCAFE", _userName, _userTel1, _userTel2, _userTel3, "", "", userWantArea, isBuildingOwner, _ask).then(({ loading, data: { result, resultCode, resultMessage } }) => {
      setIsLoading(false);
			if (resultCode === "S1000") {
        window.wcsLog();  // 네이버 검색 전환코드 실행
        window.gtag_report_conversion(); // gtag
        window.gtag_report_conversion_mirae(); // gtag
        window.gtag_mirae(); // gtag
        window.kakaoMoment();
        _doOpenDialog("신청이 완료되었습니다.", "Complete");
			} else {
				_doOpenDialog("문제가 발생하였습니다.<br/>다시 시도 해주세요.");
      }
    });
  }

  function _doConsultingCancel() {
    _doOpenDialog("상담접수를<br/>취소하시겠습니까?", "Cancel");
  }

  return (
    <div id="FranchiseNewComponent" style={{ overflowX: "hidden" }}>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
        <div className="container">
          <a className="navbar-brand" href="#page-top"><img src={NavLogo} alt="..." /></a>
          
          <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ms-auto py-5 py-lg-0">
              <li className="nav-item"><a className="nav-link" href="#Why">WHY</a></li>
              <li className="nav-item"><a className="nav-link" href="#Reason">FASTCAFE</a></li>
              <li className="nav-item"><a className="nav-link" href="#Store">STORE</a></li>
              <li className="nav-item"><a className="nav-link" href="#Procedure">PROCEDURE</a></li>
              <li className="nav-item"><a className="nav-link" href="#Consulting">CONSULTING</a></li>
              <li className="nav-item"><a href="/brand" className="nav-link btn btn-danger" title="BRAND"><span>BRAND</span></a></li>
            </ul>
          </div>
        </div>
      </nav>

      <header>
        <div id="homeIndicators" className="col-12 p-0 carousel slide" data-bs-ride="carousel" data-bs-interval="3000" data-bs-pause="false">
          <div className="carousel-inner">
            <div className="carousel-indicators">
            { banners && banners.map(( entity, i ) => {
              if(i === 0) {
                return <button key={i} type="button" data-bs-target="#homeIndicators" data-bs-slide-to={i} className="active" aria-current="true" aria-label={`Slide ${i + 1}`}></button>
              } else {
                return <button key={i} type="button" data-bs-target="#homeIndicators" data-bs-slide-to={i} aria-label={`Slide ${i + 1}`}></button>  
              }
            }) }
            </div>
            { banners && banners.map(( entity, i ) => {
              if(entity.linkUrl) {
                return <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`}><a href={ entity.linkUrl } target={ "_" + entity.target }><img className="img-fluid" src={ isMobile ? entity.mobileImgUrl : entity.pcImgUrl } alt="" /></a></div>  
              } else {
                return <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`}><img className="img-fluid" src={ isMobile ? entity.mobileImgUrl : entity.pcImgUrl } alt="" /></div>
              }
            }) }

            <button className="carousel-control-prev" type="button" data-bs-target="#homeIndicators" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#homeIndicators" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </header>

      <section className="page-section page-sub bg-secondary">
        <div className="container py-0 py-xl-4 text-center">
          <p className="h3 text-keep text-white fw-bold">창업 아이템을 고민하세요?</p>				
        </div>
      </section>

      <section id="Why" className="page-section Why">
        <div className="container my-5 my-xl-0">
          <div className="row justify-content-center">
            <div ref={whyRef1} className={`col text-center ${isWhyVisible1 ? "show" : "hide"}`}>
              <h3 className="mt-0 text-keep">이제는 무인 창업이 대세입니다.</h3>
              <hr className="divider" />
            </div>

            <div className="row p-0 mt-5">
              <div ref={whyRef2} className={`col-12 col-lg-6 text-center ${isWhyVisible2 ? "show_l" : "hide_l"}`}>
                <img className="pe-xl-5 img-fluid" src={Why1} alt="최저시급 변화" />
              </div>

              <div ref={whyRef3} className={`col-12 col-lg-6 mt-5 mt-lg-0 align-self-start ${isWhyVisible3 ? "show_r" : "hide_r"}`}>
                <ul className="nav nav-tabs border-0 justify-content-center" id="whyTab" role="tablist">
                  <li className="nav-item col-6" role="presentation">
                    <button className="nav-link rounded-0 fs-4 active" id="why1-tab" data-bs-toggle="tab" data-bs-target="#why1" type="button" role="tab" aria-controls="why1" aria-selected="true">유인카페<br/><span className="fw-bold">高인건비</span></button>
                  </li>
                  <li className="nav-item col-6" role="presentation">
                    <button className="nav-link rounded-0 fs-4" id="why2-tab" data-bs-toggle="tab" data-bs-target="#why2" type="button" role="tab" aria-controls="why2" aria-selected="false">유인카페<br/><span className="fw-bold">복잡한 인력관리</span></button>
                  </li>
                </ul>
                <div className="tab-content" id="whyTabContent">
                  <div className="tab-pane border border-dark active" id="why1" role="tabpanel" aria-labelledby="why1-tab">
                    <div className="container">
                      <div className="row p-4 p-lg-5">
                        <div className="col-12">
                          <div className="row p-2 border border-dark rounded-pill text-center">
                            <div className="col-6 fs-3 h-100 my-auto">08:00 ~ 17:00(9h)</div>
                            <div className="col-6 fs-2 h-100 my-auto">= 일당 86,580원</div>
                          </div>
                          <div className="row p-2 mt-2 border border-dark rounded-pill text-center">
                            <div className="col-6 h-100 my-auto">
                              <div className="fs-5">시간 수당 x 1.5배 적용</div>
                              <div className="fs-3">17:00 ~ 22:00(5h)</div>
                            </div>
                            <div className="col-6 h-100 my-auto fs-2">= 일당 72,150원</div>
                          </div>
                        </div>

                        <div className="col-12 fs-2 my-3 text-center">X <span className="px-3">31일</span> =</div>

                        <div className="col-12 h-100 my-auto text-center">
                          <div className="fs-3">1인 당, 한달 인건비</div>
                          <div className="fs-1 text-orange fw-bold">약 500만원</div>
                          <div className="fs-3">(23' 31일 근무 기준)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane border border-dark" id="why2" role="tabpanel" aria-labelledby="why2-tab">
                    <div className="container p-3 text-center">
                      <div className="row gx-0">
                        <div className="row gx-0 col-12">
                          <div className="col-6 p-2">
                            <div className="fs-2 py-2 border border-dark rounded-pill">근무 모니터링</div>
                          </div>
                          <div className="col-6 p-2">
                            <div className="fs-2 py-2 border border-dark rounded-pill">근태관리</div>
                          </div>
                        </div>
                        <div className="row gx-0 col-12">
                          <div className="col-6 p-2">
                            <div className="fs-2 py-2 border border-dark rounded-pill">퇴직금</div>
                          </div>
                          <div className="col-6 p-2">
                            <div className="fs-2 py-2 border border-dark rounded-pill">4대보험</div>
                          </div>
                        </div>
                        <div className="row gx-0 col-12">
                          <div className="col-6 p-2">
                            <div className="fs-2 py-2 border border-dark rounded-pill">의무교육</div>
                          </div>
                          <div className="col-6 p-2">
                            <div className="fs-2 py-2 px-sm-1 border border-dark rounded-pill">긴급인력대기<span className="fs-5">(점주)</span></div>
                          </div>
                        </div>
                        <div className="row gx-0 col-12">
                          <div className="col-6 p-2">
                            <div className="fs-2 py-2 border border-dark rounded-pill">근무계획</div>
                          </div>
                          <div className="col-6 p-2">
                            <div className="fs-2 py-2 border border-dark rounded-pill">임금 협상</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div ref={whyRef4} className={`col mt-5 text-center text-keep ${isWhyVisible4 ? "show" : "hide"}`}>
              <p className="h3 fw-bold">“&nbsp;&nbsp;인건비 ZERO! 인건비는 곧 점주의 수익!&nbsp;&nbsp;”</p>
              <p className="h3 fw-bold">복잡한 인력관리에서 해방!</p>
            </div>
          </div>
        </div>
      </section>

      <section id="Coffee" className="page-section Coffee">
        <div className="container my-5 my-xl-0">
          <div className="row gx-0 justify-content-center">
            <div ref={coffeeRef1} className={`col text-center ${isCoffeeVisible1 ? "show" : "hide"}`}>
              <h3 className="mt-0 text-keep">유행없고 대중적인 창업아이템 '커피'</h3>
              <hr className="divider" />
            </div>

            <div className="row gx-0 p-0 mt-0 mt-lg-5">
              <div ref={coffeeRef2} className={`row gx-0 carousel-dark col-12 col-lg-6 ${isCoffeeVisible2 ? "show_l": "hide_l"}`}>
                <button className="col-1 p-0 m-0" type="button" data-bs-target="#whyIndicators" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                </button>

                <div id="whyIndicators" className="col-10 p-0 carousel slide" data-bs-ride="carousel" data-bs-interval="3000" data-bs-pause="false">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={Why2} className="d-black w-100" alt="" />
                    </div>
                    <div className="carousel-item">
                      <img src={Why3} className="d-black w-100" alt="" />
                    </div>
                  </div>
                </div>
                
                <button className="col-1 p-0 m-0" type="button" data-bs-target="#whyIndicators" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                </button>
              </div>

              <div ref={coffeeRef3} className={`mt-3 mt-sm-0 col-12 col-lg-6 d-flex flex-column justify-content-evenly text-center ps-lg-5 text-lg-start ${isCoffeeVisible3 ? "show_r" : "hide_r"}`}>
                <div>
                  <p className="mb-1 mb-xl-3 fs-2">국내 커피 수입액/수입량</p>
                  <p className="fs-2">매년 사상&nbsp;&nbsp;<span className="fs-1 text-orange">최대치 경신 중</span></p>
                </div>
                <div>
                  <p className="mb-1 mb-xl-3 fs-2">한국인의 1인당 연간 커피 소비량은</p>
                  <p className="fs-2">세계 커피 소비량의&nbsp;&nbsp;<span className="fs-1 text-orange">3배 수준!</span></p>
                </div>
              </div>
            </div>

            <div ref={coffeeRef4} className={`col mt-3 mt-xl-5 text-center text-keep ${isCoffeeVisible4 ? "show" : "hide"}`}>
              <p className="h3 fw-bold">“&nbsp;&nbsp;무인 창업으로 매년 인상되는 인건비 고민말고,&nbsp;&nbsp;”</p>
              <p className="h3 fw-bold">매년 지속적으로 성장하는 커피 시장과 함께하세요!</p>
            </div>
          </div>
        </div>
      </section>

      <section id="Reviewer" className="page-section bg-gray-200">
        <div className="container text-center my-5 my-xl-0" style={{ position: "relative" }}>
          <h3 ref={reviewerRef1} className={isReviewerVisible1 ? "show" : "hide"}>무인카페 창업 성공 스토리</h3>

          <div ref={reviewerRef2} className={`row gx-0 text-center carousel-dark ${isReviewerVisible2 ? "show" : "hide"}`}>
            <button className="col-1 p-0 m-0" type="button" data-bs-target="#reviewerIndicators" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            </button>

            <div id="reviewerIndicators" className="col-10 p-0 carousel slide" data-bs-ride="carousel" data-bs-interval="3000" data-bs-pause="false">
              <div className="carousel-inner">
                { reviewers && reviewers.map(( entity, i ) => {
                  return <div key={i} className={i === 0 ? "carousel-item active" : "carousel-item"} onClick={() => _dialogReviewerOpen(entity)}>
                  <div className="row">
                    <div className="contents col-12 fs-4 text-keep my-3">
                      <p dangerouslySetInnerHTML={{ __html: entity.contents }}></p>
                    </div>
                    <div className="col-12 fs-3 text-keep">{ entity.storeName }</div>
                    <hr className="divider mt-0 mb-1"></hr>
                    <div className="col-12 fs-3 text-keep">{ entity.memberName }</div>
                  </div>
                </div>
                }) }
              </div>
            </div>

            <button className="col-1 p-0 m-0" type="button" data-bs-target="#reviewerIndicators" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </section>

      <section id="Reason" className="page-section">
        <div className="bg"></div>

        <div className="container text-center text-keep text-white my-md-0">
          <div ref={reasonRef1} className="gx-0 m-0 h-100 d-flex flex-column justify-content-between">
            <div className={`col-12 h-35 d-flex flex-column justify-content-center align-items-center ${isReasonVisible1 ? "show" : "hide"}`}>
              <div className="row">
                <p className="h3 text-white fw-bold">토즈가 만든 우리동네 무인카페 '패스트카페'</p>
                <p className="h5 text-white mt-3 align-self-center my-auto">
                  “전국 스페셜티를 가장 빠르게”<br/>
                  전국 유명 로스터리 커피를 빠르게 만날 수 있는 '스페셜티 커피 전문 편집숍' 패스트카페입니다.<br/>
                  타 무인카페와 차별화된 아이템으로 우리 동네 최고의 카페 맛집을 개업해보세요!
                </p>
              </div>
            </div>

            <div ref={reasonRef2} className="col-12 h-65 content p-4">
              <p className={`fs-2 mb-3 mb-lg-4 mb-xl-5 text-keep ${isReasonVisible2 ? "show" : "hide"}`}>당신이 패스트카페를 선택할 수 밖에 없는 <span className="text-orange fw-bold">6가지 이유!</span></p>
              <div className="row mt-0 mt-lg-4 mt-xl-5">
                <div ref={reasonRef3} className={`col-12 col-md-6 ${isReasonVisible3 ? "show" : "hide"}`}>
                  <div className="d-flex flex-row">
                    <p className="fs-1 pe-3 text-orange fw-bold lh-1">01</p>
                    <div className="text-start">
                      <p className="fs-3 text-orange fw-bold mb-1">저렴한 투자 비용</p>
                      <p className="fs-4">군더더기 없이 꼭 필요한 비용만으로<br/>창업이 가능합니다.</p>
                    </div>
                  </div>
                </div>
                <div ref={reasonRef4} className={`col-12 col-md-6 ${isReasonVisible4 ? "show" : "hide"}`}>
                  <div className="d-flex flex-row">
                    <p className="lh-1 fs-1 pe-3 text-orange fw-bold">02</p>
                    <div className="text-start">
                      <p className="fs-3 text-orange fw-bold mb-1">투명한 매출 공개</p>
                      <p className="fs-4">패스트카페 가맹점의 월 평균 매출을<br/>투명하게 공개합니다.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-0 mt-lg-2 mt-xl-4">
                <div ref={reasonRef6} className={`col-12 col-md-6 ${isReasonVisible6 ? "show" : "hide"}`}>
                  <div className="d-flex flex-row">
                    <p className="lh-1 fs-1 pe-3 text-orange fw-bold">03</p>
                    <div className="text-start">
                      <p className="fs-3 text-orange fw-bold mb-1">특별한 맛</p>
                      <p className="fs-4">고객의 대중적이고 다양한 맛의 취향을<br/>반영하였습니다.</p>
                    </div>
                  </div>
                </div>
                <div ref={reasonRef7} className={`col-12 col-md-6 ${isReasonVisible7 ? "show" : "hide"}`}>
                  <div className="d-flex flex-row">
                    <p className="lh-1 fs-1 pe-3 text-orange fw-bold">04</p>
                    <div className="text-start">
                      <p className="fs-3 text-orange fw-bold mb-1">국내 제조 커피머신</p>
                      <p className="fs-4">국내 설계/제조한 커피머신으로,<br/>매장의 안정적인 운영 및 수익 창출에 기여합니다.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-0 mt-lg-2 mt-xl-4">
                <div ref={reasonRef8} className={`col-12 col-md-6 ${isReasonVisible8 ? "show" : "hide"}`}>
                  <div className="d-flex flex-row">
                    <p className="lh-1 fs-1 pe-3 text-orange fw-bold">05</p>
                    <div className="text-start">
                      <p className="fs-3 text-orange fw-bold mb-1">본사 경쟁력</p>
                      <p className="fs-4">20년 이상 업력의 토즈의 철학을<br/>커피로 구현하였습니다.</p>
                    </div>
                  </div>
                </div>
                <div ref={reasonRef9} className={`col-12 col-md-6 ${isReasonVisible9 ? "show" : "hide"}`}>
                  <div className="d-flex flex-row">
                    <p className="lh-1 fs-1 pe-3 text-orange fw-bold">06</p>
                    <div className="text-start">
                      <p className="fs-3 text-orange fw-bold mb-1">추가 수익을 위한 결합 모델</p>
                      <p className="fs-4">카페 수익에 공간 수익을 더해 안정적인<br/>추가 수익을 창출할 수 있습니다.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Cost" className="Price page-section border-0 mt-0 mt-xl-5">
        <div className="StoreWrap container my-5 my-xl-0">
          <h3 ref={costRef1} className={isCostVisible1 ? "show" : "hide"}>01. 저렴한 투자 비용</h3>
          <h5 ref={costRef2} className={`mt-3 text-keep ${isCostVisible2 ? "show" : "hide"}`}>군더더기 없이 꼭 필요한 비용만으로 창업이 가능합니다.</h5>


          <div ref={costRef4} className={`ContentWrap ${isCostVisible4 ? "show" : "hide"}`}>
            <p className="fs-5 mt-4 mb-0 d-flex flex-row justify-content-between text-orange">
              <span>실내 투자면적 8평 기준</span>
              <span>(VAT별도, 단위 : 만원)</span>
            </p>
            <table className="Table">
              <tbody>
                <tr className="TableRow bg-orange">
                  <td colSpan={2} className="TableCell">구분</td>
                  <td className="TableCell">항목</td>
                  <td className="TableCell">단가</td>
                  <td className="TableCell">수량</td>
                  <td className="TableCell">금액</td>
                  <td className="TableCell">비고</td>
                </tr>
                <tr className="TableRow">
                  <td className="TableCell">필수</td>
                  <td className="TableCell">고정비</td>
                  <td className="TableCell">가맹비</td>
                  <td className="TableCell Charge">1,000</td>
                  <td className="TableCell Charge">1</td>
                  <td className="TableCell Charge">
                    <span className="Cancel">1,000</span>
                    <span>&nbsp;→ 200</span>
                  </td>
                  <td className="TableCell">상표 사용권, 지역 영업권 보장<br/>한시적 프로모션 적용</td>
                </tr>
                <tr className="TableRow">
                  <td className="TableCell">필수</td>
                  <td className="TableCell">고정비</td>
                  <td className="TableCell">교육비</td>
                  <td className="TableCell Charge">100</td>
                  <td className="TableCell">1</td>
                  <td className="TableCell Charge">100</td>
                  <td className="TableCell">가맹점 운영 및 매뉴얼 제공</td>
                </tr>
                <tr className="TableRow">
                  <td className="TableCell">필수</td>
                  <td className="TableCell">고정비</td>
                  <td className="TableCell">커피머신</td>
                  <td className="TableCell Charge">2,160</td>
                  <td className="TableCell">1</td>
                  <td className="TableCell Charge">2,160</td>
                  <td className="TableCell">스마트커피머신 1대, 설치비 포함</td>
                </tr>
                <tr className="TableRow">
                  <td className="TableCell">필수</td>
                  <td className="TableCell">변동비</td>
                  <td className="TableCell">내부<br/>인테리어</td>
                  <td className="TableCell Charge">180</td>
                  <td className="TableCell">8</td>
                  <td className="TableCell Charge">1,440</td>
                  <td className="TableCell">
                    8평 기준<br/>현장 실사 후 실행가로 최종 진행합니다.
                  </td>
                </tr>
                <tr className="TableRow">
                  <td className="TableCell">필수</td>
                  <td className="TableCell">고정비</td>
                  <td className="TableCell">음향설비</td>
                  <td className="TableCell Charge">35</td>
                  <td className="TableCell">1</td>
                  <td className="TableCell Charge">35</td>
                  <td className="TableCell">중앙제어 방식의 음향설비</td>
                </tr>
                <tr className="TableRow">
                  <td className="TableCell">필수</td>
                  <td className="TableCell">고정비</td>
                  <td className="TableCell">운영 인쇄물</td>
                  <td className="TableCell Charge">15</td>
                  <td className="TableCell">1</td>
                  <td className="TableCell Charge">15</td>
                  <td className="TableCell">매장 연출 포스터 등</td>
                </tr>
                <tr className="TableRow bg-gray-300">
                  <td colSpan={5} className="TableCell">합계</td>
                  <td className="TableCell Charge">3,950</td>
                  <td className="TableCell"></td>
                </tr>
                <tr className="TableRow bg-orange">
                  <td colSpan={5} className="TableCell">
                    <span className="d-none d-md-inline-block">커피머신 할부 진행시, 총계</span>
                    <span className="d-block d-md-none">커피머신 할부<br/>진행시, 총계</span>
                  </td>
                  <td className="TableCell Charge">1,790</td>
                  <td className="TableCell">
                    <div className="d-flex flex-column justify-content-center">
                      <div className="d-flex flex-column flex-md-row text-white">
                        <p className="text-white m-0">할부금</p>
                        <p className="text-white m-0 ms-1">월 799,424원(vat없음) 36개월 / 보증금 0원</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="mt-4 mb-0 fs-5 text-keep">- 추가공사 항목 : 간판, 내외부사인물, 수도공사, 냉난방기, 카페의탁자, 바닥시공 등</p>
            <p className="mt-2 mb-0 fs-5 text-keep">- 철거, 전기승압, 소방공사 등 특수한 건물 환경에 따른 별도 공사 항목이 발생될 수 있습니다.</p>
          </div>
        </div>
      </section>

      <section id="Interior" className="page-section">
        <div className="container my-5 my-xl-0 text-center">
          <p ref={interiorRef1} className={`h3 ${isInteriorVisible1 ? "show": "hide"}`}>인테리어 둘러보기</p>

          <div id="interiorIndicators" ref={interiorRef2} className={`col-12 mt-4 carousel slide ${isInteriorVisible2 ? "show": "hide"}`} data-bs-ride="carousel" data-bs-interval="3000" data-bs-pause="false">
            <div className="carousel-inner">
              <div className="carousel-indicators">
                { interiors && interiors.map(( entity, i ) => {
                  return <button key={i} type="button" data-bs-target="#interiorIndicators" data-bs-slide-to={i} className={i === 0 ? "active": ""} aria-current="true" aria-label={`Slide ${i + 1}`}></button>
                })}
              </div>

              { interiors && interiors.map(( entity, i ) => {
                return  <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
                  <img className="img-fluid" src={entity} alt="" />
                </div>   
              })}
            </div>

            <div className="mt-4 d-flex flex-row justify-content-center slide-button-group">
              <button type="button" className="slide-button-prev mx-2" data-bs-target="#interiorIndicators" data-bs-slide="prev"></button>
              <button type="button" className="slide-button-next mx-2" data-bs-target="#interiorIndicators" data-bs-slide="next"></button>
            </div>
          </div>
        </div>
      </section>

      <section id="Sales" className="page-section">
        <div className="container my-5 my-xl-0">
          <h3 ref={salesRef1} className={isSalesVisible1 ? "show" : "hide"}>02. 투명한 매출 공개</h3>
          <h5 ref={salesRef2} className={`mt-3 text-keep ${isSalesVisible2 ? "show" : "hide"}`}>패스트카페 가맹점의 월 평균 매출을 투명하게 공개합니다.</h5>

          <div ref={salesRef3} className={`my-5 Card ${isSalesVisible3 ? "show" : "hide"}`}>
            <div className="CardWrap">
              <Carousel
                slides={sales}
                goToSlide={goToSalesSlide}
                offsetRadius={offsetRadius}
                showNavigation={showNavigation}
                animationConfig={config.gentle}
              />
            </div>

            <button className="ArrowLeft" onClick={() => _goToSalesSlide(-1)}><img src={ArrowLeft} alt="" /></button>
            <button className="ArrowRight" onClick={() => _goToSalesSlide(1)}><img src={ArrowRight} alt="" /></button>
          </div>
        </div>
      </section>

      <section id="Competitive2" className="page-section">
        <div className="container my-5 my-xl-0">
          <h3 ref={competitive2Ref1} className={isCompetitive2Visible1 ? "show" : "hide"}>03. 특별한 맛</h3>
          <h5 ref={competitive2Ref2} className={`mt-3 text-keep ${isCompetitive2Visible2 ? "show" : "hide"}`}>커피도 음식입니다.<br/>맛없는 음식점은 절대 오래가지 못합니다.</h5>
          <p ref={competitive2Ref3} className={`fs-3 mt-2 mt-xl-4 text-start text-keep ${isCompetitive2Visible3 ? "show" : "hide"}`}>“맛있는 음식의 기준: 신선한 재료, 완벽한 레시피, 요리사의 손맛”</p>

          <div className="row gx-0 d-flex justify-content-center mb-5">
            <div ref={competitive2Ref4} className={`row col-12 border border-dark p-0 ${isCompetitive2Visible4 ? "show" : "hide"}`}>
              <div className="col-4 py-1 bg-orange text-white text-center text-keep fs-3 d-flex justify-content-center align-items-center">신선한 재료</div>
              <div className="col-8 py-1 text-center text-keep fs-3">최상위 품질에만 주어지는 ‘스페셜티’ 원두 사용</div>
            </div>
            <div ref={competitive2Ref5} className={`row col-12 border border-dark p-0 my-2 ${isCompetitive2Visible5 ? "show" : "hide"}`}>
              <div className="col-4 py-1 bg-orange text-white text-center text-keep fs-3 d-flex justify-content-center align-items-center">완벽한 레시피</div>
              <div className="col-8 py-1 text-center text-keep fs-3">토즈 고객 1,200만 명 대상의 연구 결과로 대중적인 맛 구현</div>
            </div>
            <div ref={competitive2Ref6} className={`row col-12 border border-dark p-0 ${isCompetitive2Visible6 ? "show" : "hide"}`}>
              <div className="col-4 py-1 bg-orange text-white text-center text-keep fs-3 d-flex justify-content-center align-items-center">요리사의 손맛</div>
              <div className="col-8 py-1 text-center text-keep fs-4">언제라도 균일한 맛을 낼 수 있는 국내 설계/제조의 전문 커피머신 사용<br/>스위스 명품 커피머신 제조사의 브로맥 모듈 탑재로 최고의 맛 구현</div>
            </div>
          </div>
        </div>
      </section>

      <section id="Item" className="page-section">
        <div className="container my-5 my-xl-0 text-center">
          <h3 className="text-keep">패스트카페의 판매 상품 리스트</h3>
          <hr className="divider"></hr>
          <h5 className="mb-5 text-keep">커피머신의 기본 음료 25종 및 셀럽머신(밴딩머신) 30종을 제공합니다.</h5>

          <ul className="nav justify-content-center mb-5">
            <li className="nav-item">
              <button className={`btn nav-link fs-5 rounded-0 border-0 ${itemType === "" ? "active" : ""}`} onClick={() => _listItem(true, "")}>All</button>
            </li>
            <li className="nav-item">
              <button className={`btn nav-link fs-5 rounded-0 border-0 ${itemType === "COFFEE" ? "active" : ""}`} onClick={() => _listItem(true, "COFFEE")}>커피머신</button>
            </li>
            <li className="nav-item">
              <button className={`btn nav-link fs-5 rounded-0 border-0 ${itemType === "CELEB" ? "active" : ""}`} onClick={() => _listItem(true, "CELEB")}>셀럽머신</button>
            </li>
          </ul>

          <div className="row gx-3 gy-3">
            { items && items.map(( entity, i ) => {
              return <div key={i} className="col-6 col-md-4 col-lg-3">
                <div className="item">
                  <img className="py-2" src={entity.imgThumbUrl} alt="" />
                  { entity.imgDetailUrl && <div className="card-img-overlay p-0 bg-orange bg-opacity-10 d-flex opacity-0" onClick={() => _dialogItemOpen(entity)}>
                    <div className="align-self-center mx-auto">
                      <div className="icon-plus"></div>
                    </div>
                  </div> }
                </div>
              </div>
            }) }
          </div>

          { !isItemLastPage &&
            <div className="row mb-0 mb-lg-5 mt-4 d-flex justify-content-center">
              <button className="btn more" onClick={() => _listItem(false, itemType)}>더 보기</button>
            </div>
          }
        </div>
      </section>

      <section id="Competitive3" className="page-section">
        <div className="container my-5 my-xl-0">
          <h3 ref={competitive3Ref1} className={isCompetitive3Visible1 ? "show" : "hide"}>04. 국내 설계/제조한 안정적인 커피머신 사용!</h3>
          <p ref={competitive3Ref3} className={`fs-3 mt-2 mt-xl-4 text-start text-keep ${isCompetitive3Visible3 ? "show" : "hide"}`}>커피머신은 겉보기와 다르게 매우 다양한 기능이 있어 복잡하고 예민합니다. 패스트 커피머신은 국내 설계/제조한 커피머신으로, 매장의 안정적인 운영 및 수익 창출에 기여합니다. 패스트 셀럽머신은 디저트(마카롱, 쿠키), 유명 카페의 커피 제품(콜드브루 등) 및 특별한 음료(티탄산, 밀크티 등)를 제공합니다.</p>

          <div className="row gx-5 gy-3 mt-2 mt-xl-5 Machine">
            <div ref={competitive3Ref4} className={`col-12 col-md-4 ContentWrap ${isCompetitive3Visible4 ? "show_l" : "hide_l"}`}>
              <div className="Content border border-primary h-100">
                <img className="MachineImage" src={ Machine1 } alt="패스트 커피머신 분리형" />
                <div className={"DescWrap" + (!isOpenMachine1 ? " Off" : "")} onMouseEnter={() => setIsOpenMachine1(true)} onMouseLeave={() => setIsOpenMachine1(false)}>
                  <div className="Desc">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <p className="Title my-0">패스트 커피머신 분리형</p>
                      <i className="Arrow"></i>
                    </div>
                    <p className="Sub text-keep">주문과 동시에 고압추출 에스프레소 방식의 프리미엄 커피를 제공합니다.</p>
                    <p>ㆍ HOT/ICED 음료 제공(듀얼컵)</p>
                    <p>ㆍ 23.8Inch 터치 키오스크</p>
                    <p>ㆍ 일괄 결제 기능</p>
                    <p>ㆍ 카드, 삼성페이 결제</p>
                    <p>ㆍ 원격 제어 가능</p>
                  </div>
                </div>
              </div>
            </div>
            <div ref={competitive3Ref5} className={`col-12 col-md-4 ContentWrap ${isCompetitive3Visible5 ? "show_r" : "hide_r"}`}>
              <div className="Content border border-primary h-100">
              <img className="MachineImage" src={ Machine2 } alt="패스트 커피머신 일체형" />
                <div className={"DescWrap" + (!isOpenMachine2 ? " Off" : "")} onMouseEnter={() => setIsOpenMachine2(true)} onMouseLeave={() => setIsOpenMachine2(false)}>
                  <i className="Arrow"></i>
                  <div className="Desc">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <p className="Title my-0">패스트 커피머신 일체형</p>
                      <i className="Arrow"></i>
                    </div>
                    <p className="Sub text-keep">롯데기공 제조/생산으로 높은 기기 안정성을 제공합니다.</p>
                    <p>ㆍ HOT/ICED음료 24가지</p>
                    <p>ㆍ 32인치 터치 LCD</p>
                    <p>ㆍ 카드, 페이 결제</p>
                    <p>ㆍ 16온즈 180개 컵 사용</p>
                    <p>ㆍ 색상: 블랙, 화이트</p>
                  </div>
                </div>
              </div>
            </div>
            <div ref={competitive3Ref6} className={`col-12 col-md-4 ContentWrap ${isCompetitive3Visible6 ? "show_r" : "hide_r"}`}>
              <div className="Content border border-primary h-100">
                <img className="MachineImage" src={ Machine3 } alt="패스트 셀럽머신" />
                <div className={"DescWrap" + (!isOpenMachine3 ? " Off" : "")} onMouseEnter={() => setIsOpenMachine3(true)} onMouseLeave={() => setIsOpenMachine3(false)}>
                  <i className="Arrow"></i>
                  <div className="Desc">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <p className="Title my-0">패스트 셀럽머신</p>
                      <i className="Arrow"></i>
                    </div>
                    <p className="Sub text-keep">디저트와 완제품 음료를 제공합니다.</p>
                    <p>ㆍ 디저트류(마카롱, 쿠키, 브라우니 등)</p>
                    <p>ㆍ 음료류(티탄산, 밀크티, 콜드브루 등)</p>
                    <p>ㆍ 냉각장치 : 7℃(3 ~ 20℃ 사이 설정)</p>
                    <p>ㆍ 상하 리프트 방식(스파이럴&벨트 혼합)</p>
                    <p>ㆍ 신용카드, 스마트페이 결제</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="About" className="page-section">
        <div className="container mt-5">
          <h3 ref={aboutRef1} className={isAboutVisible1 ? "show" : "hide"}>05. 본사 경쟁력</h3>
          <h5 ref={aboutRef2} className={`mt-3 text-keep ${isAboutVisible2 ? "show" : "hide"}`}>토즈의 철학을 커피로 구현하는 공간, 패스트카페</h5>
          <p ref={aboutRef3} className={`fs-3 mt-2 mt-md-4 ${isAboutVisible3 ? "show" : "hide"}`}>2001년 설립한 (주)피투피시스템즈의 대표 브랜드 '토즈'는 고객의 목적과 가치가 존중되고 실현되는 최적의 공간 서비스를 제공한다는 철학 아래, 2012년 강남에 스마트카페를 오픈하면서 본격적으로 커피 맛을 연구하였습니다.  토즈가 다양한 고객의 가치를 존중하듯 패스트카페는 다양한 유명 카페들의 맛을 모두 담고자 합니다.</p>

          <div className="row mt-5 gx-4 gx-lg-5">
            <div ref={aboutRef4} className={`col-lg-3 col-md-6 mt-3 text-center ${isAboutVisible4 ? "show" : "hide"}`}>
              <div className="mb-2"><img className="icon" src={AboutIcon1} alt="공간서비스 NO.1" /></div>
              <p className="h4 mb-2">공간서비스 NO.1</p>
              <p className="text-muted mb-0 text-keep">고객의 성공을 위해 스터디, 미팅, 커뮤니티, 세미나 등의 다양한 공간을 제공</p>
            </div>
            <div ref={aboutRef5} className={`col-lg-3 col-md-6 mt-3 text-center ${isAboutVisible5 ? "show" : "hide"}`}>
              <div className="mb-2"><img className="icon" src={AboutIcon2} alt="업력 20년 이상" /></div>
              <p className="h4 mb-2">업력 20년 이상</p>
              <p className="text-muted mb-0 text-keep">2001년 설립하여 꾸준한 노력으로 쌓아올린 사업 노하우 보유</p>
            </div>
            <div ref={aboutRef6} className={`col-lg-3 col-md-6 mt-3 text-center ${isAboutVisible6 ? "show" : "hide"}`}>
              <div className="mb-2"><img className="icon" src={AboutIcon3} alt="차별화" /></div>
              <p className="h4 mb-2">차별화</p>
              <p className="text-muted mb-0 text-keep">시장의 트렌드를 읽어 새로운 길을 개척하는 기획 능력 우수</p>
            </div>
            <div ref={aboutRef7} className={`col-lg-3 col-md-6 mt-3 text-center ${isAboutVisible7 ? "show" : "hide"}`}>
              <div className="mb-2"><img className="icon" src={AboutIcon4} alt="운영솔루션" /></div>
              <p className="h4 mb-2">운영솔루션</p>
              <p className="text-muted mb-0 text-keep">고객-지점-본사를 연결하는 자체 솔루션 개발 및 운영</p>
            </div>
          </div>

          <p ref={aboutRef8} className={`fs-1 text-center fw-semi my-3 ${isAboutVisible8 ? "show" : "hide"}`}>Since 2001, 누적 이용자 수 5,200만 명!</p>
        </div>

        <div className="content">
          <div className="position-relative row g-0 p-0 mx-0 mt-2">
            <Slider className="carousel-inner" ref={aboutSlideRef} afterChange={(idx) => _afterChangeForAbout(idx)} beforeChange={(current, next) => _beforeChangeForAbout(current, next)} {...settingsForAbout}>
              <div className="carousel-item active">
                <div className="card">
                  <div className="img-wrapper">
                    <img src={AboutPortfolio1} className="d-block w-100" alt="..." />
                  </div>
                </div>
                <p className="mx-4 my-3 my-md-4 fs-4 fw-semi">2002년 토즈 모임센터 오픈<br/>대한민국 공간 서비스의 시작</p>
              </div>
              <div className="carousel-item">
                <div className="card">
                  <div className="img-wrapper">
                    <img src={AboutPortfolio2} className="d-block w-100" alt="..." />
                  </div>
                </div>
                <p className="mx-4 my-3 my-md-4 fs-4 fw-semi">2009년 토즈 워크센터 오픈<br/>새로운 비즈니스 공간 창조</p>
              </div>
              <div className="carousel-item">
                <div className="card">
                  <div className="img-wrapper">
                    <img src={AboutPortfolio3} className="d-block w-100" alt="..." />
                  </div>
                </div>
                <p className="mx-4 my-3 my-md-4 fs-4 fw-semi">2010년 토즈 스터디센터 오픈<br/>프리미엄 독서실 시장 개척</p>
              </div>
              <div className="carousel-item">
                <div className="card">
                  <div className="img-wrapper">
                    <img src={AboutPortfolio4} className="d-block w-100" alt="..." />
                  </div>
                </div>
                <p className="mx-4 my-3 my-md-4 fs-4 fw-semi">2012년 토즈 스마트카페 오픈<br/>코워킹스페이스 국내 최초 도입</p>
              </div>
              <div className="carousel-item">
                <div className="card">
                  <div className="img-wrapper">
                    <img src={AboutPortfolio5} className="d-block w-100" alt="..." />
                  </div>
                </div>
                <p className="mx-4 my-3 my-md-4 fs-4 fw-semi">2021년 패스트카페 오픈<br/>스페셜티 커피 전문 편집숍 프리미엄 무인카페</p>
              </div>
              <div className="carousel-item">
                <div className="card">
                  <div className="img-wrapper">
                    <img src={AboutPortfolio6} className="d-block w-100" alt="..." />
                  </div>
                </div>
                <p className="mx-4 my-3 my-md-4 fs-4 fw-semi">2022년 토즈밋 오픈<br/>복합공간대여 서비스 모델</p>
              </div>
            </Slider>
          </div>
        </div>

        <div className="mb-5 d-flex flex-row justify-content-center slide-button-group">
          <button type="button" className="slide-button-prev mx-2" onClick={() => aboutSlideRef.current.slickPrev()}></button>
          <button type="button" className="slide-button-next mx-2" onClick={() => aboutSlideRef.current.slickNext()}></button>
        </div>
      </section>

      <section id="With" className="page-section">
        <div className="container my-5 my-md-0">
          <div className="row mx-0 my-md-5 p-0 gx-md-5">
            <div className="col-12 col-md-6 p-0 d-flex flex-column">
              <img src={With1} className="img-fluid" alt="" />
              <img src={With2} className="img-fluid mt-3" alt="" />
            </div>

            <div className="col-12 col-md-6 text-start text-keep">
              <p className="fs-2 fw-semi mt-3 mt-md-5">성공적인 오픈을 위한 본사 지원</p>
              <p className="fs-3 fw-semi mt-3 mt-md-5 mb-0">1. 온라인 홍보 본사 지원</p>
              <p className="fs-4 mt-1 mt-md-3">네이버 스마트플레이스 등록, 블로그 마케팅 등</p>
              <p className="fs-3 fw-semi mt-3 mt-md-5 mb-0">2. 현장 오픈 마케팅 지원</p>
              <p className="fs-4 mt-1 mt-md-3">오픈 초기 안착을 위한 오픈 이벤트 세팅/교육 및 현장 지원</p>
              <p className="fs-3 fw-semi mt-3 mt-md-5 mb-0">3. 1:1 현장 교육 지원</p>
              <p className="fs-4 mt-1 mt-md-3">남녀노소 누구나 쉽게 운영하실 수 있도록 찾아가는 현장 교육 및 운영 노하우 전수</p>
            </div>
          </div>
        </div>
      </section>

      <section id="Combine" className="page-section border-bottom-0">
        <div className="container my-5">
          <h3 className="text-keep">06. 안정적 추가 수익을 위한 공간 결합형 모델 (선택)</h3>
          <h5 className="mt-3 text-keep">패스트카페는 카페 수익에 공간 수익을 더해<br/>안정적인 추가 수익을 창출할 수 있습니다. (무인카페+미팅룸 결합형)</h5>

          <div className="row">
            <div className="col">
              <img src={Moim1} className="img-fluid" alt="토즈밋" />
            </div>
          </div>
          <div className="sheet row mt-3 g-1 g-sm-2 g-md-3 fs-3 text-center text-keep">
            <div className="col-2">
              <div className="bg-orange py-1 text-white">구분</div>
            </div>
            <div className="col-5">
              <div className="bg-orange py-1 text-white">무인카페</div>
            </div>
            <div className="col-5">
              <div className="bg-orange py-1 text-white">공간대여</div>
            </div>

            <div className="col-2">
              <div className="border border-primary w-100 h-100 d-flex justify-content-center align-items-center">운영 형태</div>
            </div>
            <div className="col-5">
              <div className="border border-primary w-100 h-100 d-flex justify-content-center align-items-center">커피머신 및 밴딩머신을 통한 즉석 제조 음료,<br/>유명카페 완제품 판매</div>
            </div>
            <div className="col-5">
              <div className="border border-primary w-100 h-100 d-flex justify-content-center align-items-center">모바일 APP 예약과 결제를 통한 무인 운영 방식</div>
            </div>

            <div className="col-2">
              <div className="border border-primary w-100 h-100 d-flex justify-content-center align-items-center">타겟 고객</div>
            </div>
            <div className="col-5">
              <div className="border border-primary w-100 h-100 d-flex justify-content-center align-items-center">합리적인 가격에 고품질 커피를 즐기는 소비자</div>
            </div>
            <div className="col-5">
              <div className="border border-primary w-100 h-100 d-flex justify-content-center align-items-center">영업사원 업무 미팅, 카공족, 그룹스터디, 친목모임 등</div>
            </div>
          </div>
        </div>
      </section>

      <section id="Example" className="page-section border-bottom-0">
        <div className="container my-5">
          <div className="row justify-content-center text-keep">
            <div className="col text-center">
              <h3 className="mt-0">적용 예시 ｜ 패스트카페 신촌점 (R&D점)</h3>
              <h5 className="mt-3 text-keep">지점 인근 여러 맛집 및 카공족 등을 타겟으로 하여 카페, 공간 구성</h5>
            </div>

            <ul className="nav nav-tabs border-0 justify-content-center p-0 mt-5" id="exampleTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link rounded-0 fs-3 active" id="example1-tab" data-bs-toggle="tab" data-bs-target="#example1" type="button" role="tab" aria-controls="example1" aria-selected="true">1층 (4평)</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link rounded-0 fs-3" id="example2-tab" data-bs-toggle="tab" data-bs-target="#example2" type="button" role="tab" aria-controls="example2" aria-selected="false">지하 1층 (22평)</button>
              </li>
            </ul>
            <div className="tab-content" id="exampleTabContent">
              <div className="tab-pane text-center text-keep border border-dark p-0 active" id="example1" role="tabpanel" aria-labelledby="example1-tab">
                <p className="fs-3 px-3 px-md-5 pt-3 pt-md-5 pb-0">커피머신과 셀럽머신을 통한 음료, 간식 판매</p>
                <div id="example1Indicators" className="position-relative px-2 px-sm-3 px-md-5 pb-2 pb-sm-3 pb-md-5 pt-0 mt-3 carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner position-relative">
                    <div className="carousel-indicators">
                      <button type="button" data-bs-target="#example1Indicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#example1Indicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>

                    <div className="carousel-item active">
                      <img src={Moim2} className="d-black w-100" alt="" />
                    </div>
                    <div className="carousel-item">
                      <img src={Moim3} className="d-black w-100" alt="" />
                    </div>
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#example1Indicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#example1Indicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  </button>
                </div>
              </div>
              <div className="tab-pane text-center border border-dark p-0" id="example2" role="tabpanel" aria-labelledby="example2-tab">
                <p className="fs-3 px-3 px-md-5 pt-3 pt-md-5 pb-0">개인석, 미팅룸 구성의 공간대여 서비스 무인 운영</p>
                <div id="example2Indicators" className="position-relative px-2 px-sm-3 px-md-5 pb-2 pb-sm-3 pb-md-5 pt-0 mt-3 carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner position-relative">
                    <div className="carousel-indicators">
                      <button type="button" data-bs-target="#example2Indicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#example2Indicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#example2Indicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      <button type="button" data-bs-target="#example2Indicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>

                    <div className="carousel-item active">
                      <img src={Moim4} className="d-black w-100" alt="" />
                    </div>
                    <div className="carousel-item">
                      <img src={Moim5} className="d-black w-100" alt="" />
                    </div>
                    <div className="carousel-item">
                      <img src={Moim6} className="d-black w-100" alt="" />
                    </div>
                    <div className="carousel-item">
                      <img src={Moim7} className="d-black w-100" alt="" />
                    </div>
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#example2Indicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#example2Indicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Price" className="Price page-section">
        <div className="StoreWrap container text-center text-keep">
          <div className="col text-center mb-5">
              <h3 className="mt-0">창업 비용</h3>
              <h5 className="mt-3 text-keep">무인카페 창업비용 외에 추가되는 공간대여 서비스 면적 최소 8평 기준 투자 비용입니다.</h5>
            </div>
            <p className="fs-5 mb-0 float-end">(VAT 별도, 단위 : 만원)</p>
          <div className="ContentWrap">
            <table className="Table">
              <tbody>
                <tr className="TableRow Title">
                  <td colspan={2} className="TableCell">항목</td>
                  <td className="TableCell">단가</td>
                  <td className="TableCell">수량</td>
                  <td className="TableCell">금액</td>
                  <td className="TableCell">비고</td>
                </tr>
                <tr className="TableRow">
                  <td rowSpan={4} className="TableCell">공간대여서비스<br/>(8평 기준)</td>
                  <td className="TableCell">내부 인테리어</td>
                  <td className="TableCell Charge">200</td>
                  <td className="TableCell Charge">8</td>
                  <td className="TableCell Charge">1,600</td>
                  <td className="TableCell">설계 및 디자인, 인테리어 시공</td>
                </tr>
                <tr className="TableRow">
                  <td className="TableCell">회의가구</td>
                  <td className="TableCell Charge">80</td>
                  <td className="TableCell Charge">2</td>
                  <td className="TableCell Charge">160</td>
                  <td className="TableCell">4인 회의테이블 및 의자 SET</td>
                </tr>
                <tr className="TableRow">
                  <td className="TableCell">독립좌석</td>
                  <td className="TableCell Charge">40</td>
                  <td className="TableCell Charge">3</td>
                  <td className="TableCell Charge">120</td>
                  <td className="TableCell">1인 좌석 테이블 및 의자 SET</td>
                </tr>
                <tr className="TableRow">
                  <td className="TableCell">무인시스템</td>
                  <td className="TableCell Charge">200</td>
                  <td className="TableCell Charge">식</td>
                  <td className="TableCell Charge">200</td>
                  <td className="TableCell">출입통제 단말기</td>
                </tr>
                <tr className="TableRow bg-yellow">
                  <td colSpan={4} className="TableCell">합계</td>
                  <td className="TableCell Charge">2,080</td>
                  <td className="TableCell"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section id="Store" className="page-section border-bottom-0">
        <div className="container text-center mt-5">
          <h3 className="text-start">실시간 가맹 현황</h3>
          <h5 className="mt-3 text-start text-keep">좋은 상권, 좋은 물건을 누구보다 빠르게 선점하세요!</h5>

          <div className="position-relative row g-0 px-0 px-lg-5 py-0 mt-5 mb-2">
            <Slider className="carousel-inner" ref={openStoreSlideRef} afterChange={(idx) => _afterChangeForOpenStore(idx)} beforeChange={(current, next) => _beforeChangeForOpenStore(current, next)} {...settingsForOpenStore}>
              { openStores && openStores.map(( entity, i ) => {
                return <div key={i} className={`carousel-item ${ i === 0 ? "active" : "" }`}>
                  <div className="card">
                    <div className="img-wrapper">
                      <img src={entity.imgUrl} className="d-block w-100" alt="..." />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{entity.type}</h5>
                      <p className="card-text">{entity.address}</p>
                    </div>
                  </div>
                </div>
              }) }
            </Slider>
            <button className="ArrowLeft bg-orange col-1" onClick={() => openStoreSlideRef.current.slickPrev()}><img src={ArrowLeft} alt="" /></button>
            <button className="ArrowRight bg-orange col-1" onClick={() => openStoreSlideRef.current.slickNext()}><img src={ArrowRight} alt="" /></button>
          </div>
          <button className="btn more" onClick={() => window.open("https://fastcafe.co.kr/store/all")}>운영 지점 보기</button>
        </div>
      </section>

      <section id="News" className="News page-section mt-5">
        <div className="container my-5">
          <div className="row gx-0">
            <div className="mt-0 col-12 col-md-5">
              <img src={ NewBg } className="img-fluid h-100 pe-md-3 pe-lg-4" alt="" style={{ objectFit: "cover", objectPosition: "left" }} />
            </div>
            <div className="row px-0 mx-0 col-12 col-md-7 mt-4 mt-md-0 border-0 border-bottom border-primary d-flex flex-column">
              <div className="col-1 px-0 fw-bold text-hr text-hr-double" style={{ lineHeight: "2.5rem" }}>
                <span className="text-hr-text fs-1">뉴스<span className="text-orange">&이벤트</span></span>
                <Link to="/news/notice" className="fs-4 text-dark text-hr-text"><span>더보기</span></Link>
              </div>
              <div className="newsList px-0 my-3 my-sm-2 my-md-4 col d-flex flex-column justify-content-center">
                { newsList ? 
                  newsList.map((entity, idx) => (
                    <a key={idx} className="row g-0 my-1 my-sm-1 my-md-2" href={entity.linkUrl ? entity.linkUrl : ("/news/" + ( entity.type.toLowerCase() === "event" ? "store" : entity.type.toLowerCase() ) + "/" + entity.id)} target={ entity.linkUrl ? "_blank" : "_self" } rel="noreferrer">
                      <span className="col-2 fs-5 text-orange text-start">{ Common.convertNewsTypeName(entity.type) }</span>
                      <span className="col-7 fs-5 text-truncate text-primary">{ entity.title }</span>
                      <span className="col-3 fs-5 text-dark text-end">{ entity.regdate }</span>
                    </a>
                  ))
                  : <p className="Nodata">등록된 게시물이 없습니다.</p>
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Procedure" className="page-section">
        <div className="container my-5 my-xl-0 text-center text-keep">
          <h3 ref={procedureRef1} className={isProcedureVisible1 ? "show" : "hide"}>창업 절차</h3>
          <h5 ref={procedureRef2} className={`mt-3 ${isProcedureVisible2 ? "show" : "hide"}`}>성공적인 창업을 위해 전문 영업 담당자가 1:1 컨설팅합니다.</h5>
          <div className="row mt-3 mb-0 p-0 g-0">
            <div ref={procedureRef3} className={`col-6 col-lg-3 px-3 px-lg-0 py-3 d-flex flex-column align-items-center ${isProcedureVisible3 ? "show" : "hide"}`}>
              <img src={ProcedureStep1} className="img-fluid" alt="1" />
              <span className="fs-3 my-2 fw-bold text-orange">개설 및 상권상담</span>
              <span className="fs-4 text-secondary">전화 또는 방문 상담<br/>브랜드 창업 설명<br/>투자 자금 계획<br/>희망 상권 확인 및 제안</span>
            </div>
            <div ref={procedureRef4} className={`col-6 col-lg-3 px-3 px-lg-0 py-3 d-flex flex-column align-items-center ${isProcedureVisible4 ? "show" : "hide"}`}>
              <img src={ProcedureStep2} className="img-fluid" alt="2" />
              <span className="fs-3 my-2 fw-bold text-orange">물건 컨설팅 의뢰</span>
              <span className="fs-4 text-secondary">물건 컨설팅 약정서 체결</span>
            </div>
            <div ref={procedureRef5} className={`col-6 col-lg-3 px-3 px-lg-0 py-3 d-flex flex-column align-items-center ${isProcedureVisible5 ? "show" : "hide"}`}>
              <img src={ProcedureStep3} className="img-fluid" alt="3" />
              <span className="fs-3 my-2 fw-bold text-orange">물건 조사</span>
              <span className="fs-4 text-secondary">입지 타당성 검토 및 선정<br/>물건 서칭 및 검토</span>
            </div>
            <div ref={procedureRef6} className={`col-6 col-lg-3 px-3 px-lg-0 py-3 d-flex flex-column align-items-center ${isProcedureVisible6 ? "show" : "hide"}`}>
              <img src={ProcedureStep4} className="img-fluid" alt="4" />
              <span className="fs-3 my-2 fw-bold text-orange">임대차 계약</span>
              <span className="fs-4 text-secondary">점포 계약</span>
            </div>
            <div ref={procedureRef7} className={`col-6 col-lg-3 px-3 px-lg-0 py-3 d-flex flex-column align-items-center ${isProcedureVisible7 ? "show" : "hide"}`}>
              <img src={ProcedureStep5} className="img-fluid" alt="5" />
              <span className="fs-3 my-2 fw-bold text-orange">가맹 계약</span>
              <span className="fs-4 text-secondary">가맹 계약<br/>개설 일정 협의</span>
            </div>
            <div ref={procedureRef8} className={`col-6 col-lg-3 px-3 px-lg-0 py-3 d-flex flex-column align-items-center ${isProcedureVisible8 ? "show" : "hide"}`}>
              <img src={ProcedureStep6} className="img-fluid" alt="6" />
              <span className="fs-3 my-2 fw-bold text-orange">시설 공사</span>
              <span className="fs-4 text-secondary">설계 | 인/익스테리어 공사<br/>집기, 장비 설치</span>
            </div>
            <div ref={procedureRef9} className={`col-6 col-lg-3 px-3 px-lg-0 py-3 d-flex flex-column align-items-center ${isProcedureVisible9 ? "show" : "hide"}`}>
              <img src={ProcedureStep7} className="img-fluid" alt="1" />
              <span className="fs-3 my-2 fw-bold text-orange">개점 준비</span>
              <span className="fs-4 text-secondary">개점 교육<br/>정식 오픈 일자 확정<br/>초도물품 체크<br/>오픈 홍보 시작</span>
            </div>
            <div ref={procedureRef10} className={`col-6 col-lg-3 px-3 px-lg-0 py-3 d-flex flex-column align-items-center ${isProcedureVisible10 ? "show" : "hide"}`}>
              <img src={ProcedureStep8} className="img-fluid" alt="2" />
              <span className="fs-3 my-2 fw-bold text-orange">오픈</span>
              <span className="fs-4 text-secondary">정식 오픈</span>
            </div>
          </div>
        </div>
      </section>          

      <section id="Consulting" className="Consulting page-section border-bottom-0">
        <div className="container text-center my-5">
          <h3 className="text-center">상담신청</h3>
          <h5 className="mt-3 text-center text-keep">상담 신청 정보를 남겨주시면 빠른 시일 내에 연락 드리겠습니다.</h5>

          <div className="MainContent">
            <div className="Request">
              <p className="MainTitle fs-3 text-start">신청자 정보 <span>(* 필수입력 항목)</span></p>
              <div className="Content">
                <div className="Row">
                  <div className="Title fs-4">성명 *</div>
                  <div className="Input fs-4">
                    <input type="text" name="name" onKeyUp={e => setUserName2(e.target.value)} />
                  </div>
                </div>
                <div className="Row">
                  <div className="Title fs-4">휴대전화 *</div>
                  <div className="Input fs-4">
                    <input type="tel" maxLength="11" onInput={e => setUserTel2(Common.replaceNum(e.target.value))} value={ userTel2 } />
                  </div>
                </div>
                <div className="Row">
                  <div className="Title fs-4">설치장소 확보 여부</div>
                  <div className="Radio text-start isBuildingOwner">
                    <label className="Label fs-4"> 있음
                      <input type="radio" name="isBuildingOwner" defaultChecked onChange={() => setIsBuildingOwner(0)} />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="Label fs-4"> 아직없음
                      <input type="radio" name="isBuildingOwner" onChange={() => setIsBuildingOwner(1)} />
                      <span className="Checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="Row">
                  <div className="Title fs-4">설치 예정 장소</div>
                  <div className="Input fs-4">
                    <input type="text" onKeyUp={e => setUserWantArea(e.target.value)} />
                  </div>
                </div>
                <div className="Row">
                  <div className="Title fs-4">상담 주제</div>
                  <div className="Radio text-start">
                    <label className="Label fs-4"> 커피머신 단독 구매
                      <input type="radio" name="wantTime" defaultChecked onChange={() => setWantType("커피머신 단독 구매")} />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="Label fs-4"> 창업
                      <input type="radio" name="wantTime" onChange={() => setWantType("창업")} />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="Label fs-4"> 사내카페
                      <input type="radio" name="wantTime" onChange={() => setWantType("사내카페")} />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="Label fs-4"> 샵인샵
                      <input type="radio" name="wantTime" onChange={() => setWantType("샵인샵")} />
                      <span className="Checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="Row">
                  <textarea name="ask" className="fs-5" placeholder="기타 전달 말씀, 문의사항 있으신 경우 자유롭게 기재 해 주세요."  onKeyUp={e => setAsk(e.target.value)}></textarea>
                </div>
              </div>
            </div>
            <div className="Privacy">
              <p className="MainTitle fs-3 text-start">개인정보 수집 및 이용안내</p>
              <div className="Content">
                <div className="Row">
                  <p className="text-start fs-4">
                    아래와 같이 고객님의 개인정보를 수집하고 있습니다.<br/><br/>
                    <span>개인정보 수집범위 :</span> 성명, 휴대전화, 문의내용<br/>
                    <span>개인정보 수집 및 이용목적 :</span> 상담 신청 및 조회<br/>
                    <span>개인정보 수집 및 보유기간 :</span> 이용자의 개인정보는 원칙적으로
                    개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기하며
                    보유기간은 최대 3년을 넘기지 않는 것을 원칙으로 한다.
                  </p>
                </div>
                <div className="Row text-start">
                  <label className="Label fs-4"> 개인정보 수집 및 이용에 동의합니다.
                    <input type="checkbox" name="isAgree" value="true" onChange={() => setIsAgree2(!isAgree2)} />
                    <span className="Checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="BtnArea">
                  <button className="fs-4 py-3" onClick={() => _doConsultingConfirm()}>신청</button>
                  <button className="fs-4 py-3" onClick={() => _doConsultingCancel()}>취소</button>
                </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <section id="ConsultingBottom" className="page-section p-1 p-md-3 fixed-bottom bg-orange">
        <div className="container text-center">
          <div className="row d-flex align-items-center">
            <div className="d-none d-md-block info col-12 col-md-3">
              <div className="row">
                <span className="col-6 text-start text-sm-start col-md-12 text-white fs-3 mt-1 mt-md-0">창업 문의</span>
                <a href="javascript:;" className="col-6 text-end text-md-start col-md-12 text-yellow fs-2" onClick={() => _doInquiryWithGtag('전화문의text')}>1661-0227</a>
              </div>
            </div>
            <div className="d-flex col-12 col-md-9 col-lg-6 bg-green d-flex flex-column justify-content-center my-1 my-md-0">
              <div className="input-group mb-1 mb-md-2">
                <input type="text" className="form-control border-white rounded-0" placeholder="이름" onKeyUp={e => setUserName(e.target.value)} />
                <input type="text" className="form-control border-white mx-2 rounded-0" placeholder="연락처" maxLength="11" onInput={e => setUserTel(Common.replaceNum(e.target.value))} value={ userTel } />
                <button className="btn btn-consulting bg-orange border border-5 rounded-0 border-white text-white" onClick={() => _doRequestConfirm()}>문의하기</button>
              </div>
              <div className="form-check mb-0 text-start">
                <input className="form-check-input border-0 rounded-0" type="checkbox" id="isAgree" onChange={() => setIsAgree(!isAgree)} />
                <label className="form-check-label text-white" htmlFor="isAgree">개인정보처리방침 약관 동의</label>
                <button className="text-white mx-2" onClick={() => setIsPopupOpen(true)}><u>[전문보기]</u></button>
              </div>
            </div>
            <div className="d-none d-lg-flex col-12 col-md-3 mt-2 mt-md-0 justify-content-around justify-content-md-end align-items-center">
              <button className="tel d-flex justify-content-center align-items-center flex-column text-white" onClick={() => _doInquiryWithGtag('전화문의image')}>
                <img src={ConsultingTel} alt="전화문의" />
                <span>TEL</span>
              </button>
              <button className="top d-flex justify-content-center align-items-center flex-column text-white ms-md-3" onClick={() => window.scrollTo(0,0)}>
                <img src={ConsultingTop} alt="TOP" />
                <span>TOP</span>
              </button>
            </div>
          </div>
        </div>

        <div className="InquiryMenuBarWrap d-lg-none d-flex flex-column align-items-end text-primary">
          <div className={`InquiryMenuBar ${isShowInquiryMenuBar && 'show'}`}>
            <button className="tel d-flex justify-content-center align-items-center flex-column" onClick={() => _doInquiryWithGtag('전화문의')}>
              <img src={ConsultingTel} className="img-fluid" alt="전화문의" />
            </button>
            <button className="top d-flex justify-content-center align-items-center flex-column" onClick={() => window.scrollTo(0,0)}>
              <img src={ConsultingTop} className="img-fluid" alt="TOP" />
            </button>
          </div>
          <button className={`plus d-flex justify-content-start align-items-center flex-column ${isShowInquiryMenuBar && 'active'}`} onClick={() => setIsShowInquiryMenuBar(!isShowInquiryMenuBar)}>
            <img src={IconPlus} className="img-fluid" alt="" />
          </button>
        </div>
      </section>

      <div id="Layer" className={"LayerWrap " + (isPopupOpen ? "on" : "" )}>
        <div className="PopLayer">
          <div className="PopContainer">
            <p className="Title">개인정보 수집 및 이용안내</p>
            <p className="Sub">패스트카페 창업문의신청 시 아래와 같이 고객님의 개인정보를 수집하고 있습니다.</p>
            <p className="Desc">
              <span>개인정보 수집범위 :</span> 이름, 휴대전화번호<br/>
              <span>개인정보 수집 및 이용목적 :</span> 창업문의 신청 확인 및 조회<br/>
              <span>개인정보 수집 및 보유기간 :</span> 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기하며 보유기간은 최대 3년을 넘기지 않는 것을 원칙으로 한다.
            </p>
          </div>
          <button className="BtnClose" onClick={() => setIsPopupOpen(false)}></button>
        </div>
      </div>

      <div id="Dialog" className={"LayerWrap " + (isDialogOpen ? "on" : "" )}>
        <div className="PopLayer">
          <div className="PopContainer">
            <p className="Msg" dangerouslySetInnerHTML={{ __html: dialogMsg }}></p>
          </div>
          <div className="BtnGroup">
            <button className="Ok" onClick={() => dialogType === "Confirm" ? _doSubmit(dialogSubType) : _doCloseDialog()}>확인</button>
            <button className={"Cancel " + dialogType} onClick={() => _doCloseDialog()}>취소</button>
          </div>
          <button className="BtnClose" onClick={() => _doCloseDialog()}></button>
        </div>
      </div>

      { popups && popups.length !== 0 && <LayerPopup datas={popups} onClick={_bannerClick} /> }

      <div id="DialogReviewer" className={"LayerWrap " + (isReviewerDialogOpen ? "on" : "" )}>
        <div className="row content-wrap g-0">
          <div className="title col-12 bg-dark text-white text-center w-100 fs-2">
            {reviewerDetailEntity && reviewerDetailEntity.title} 창업 후기
            <button className="BtnClose" onClick={() => _dialogReviewerClose()}></button>
          </div>
          <div className="content-container col-12 bg-white w-100">
            {reviewerDetailEntity && <p className="p-2 p-md-5 content text-keep" dangerouslySetInnerHTML={{ __html: reviewerDetailEntity.detail }}></p> }
          </div>
        </div>
      </div>

      <div id="DialogItem" className={"LayerWrap " + (isItemDialogOpen ? "on" : "" )}>
        <div className="row content-wrap g-0">
          <div className="title col-12 bg-dark text-white text-center w-100 fs-2">
            <button className="BtnClose" onClick={() => _dialogItemClose()}></button>
          </div>
          <div className="content-container col-12 bg-white w-100">
            {itemDetailEntity && <p className="p-0 content text-keep"><img src={itemDetailEntity} className="img-fluid" alt="" /></p> }
          </div>
        </div>
      </div>

      { isLoading && <Loading /> }
    </div> 
  )
}