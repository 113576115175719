import axios from "axios";
import qs from "qs";
import * as Consts from "./Consts";

const instance = axios.create({
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
	},
	timeout: 30000
});
const instanceForm = axios.create({
	headers: {
		"Content-Type": "multipart/form-data",
		"x-auth-token": Consts.ACCESS_TOKEN
	},
	timeout: 30000
});

instance.interceptors.request.use(
	function (config) {
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	function (response) {
		return { loading: false, ...response };
	},
	function (error) {
		Promise.reject(error);
		return {
			loading: false,
			data: {
				resultCode: "E9999",
				resultMessage: `서버와 통신이 원활하지 않습니다.<br />잠시 후 다시 시도해 주세요.`,
				result: null
			}
		};
	}
);

export const listMain = async () => {
	return instance.post(`${Consts.CLOSE_API_URL}/listMain`, qs.stringify({ accessToken: Consts.ACCESS_TOKEN }));
};
export const listNotice = async (page, size = 10) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listNotice`, qs.stringify({ page: page, size: size, accessToken: Consts.ACCESS_TOKEN }));
};
export const listMedia = async (page, size = 10) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listMedia`, qs.stringify({ page: page, size: size, accessToken: Consts.ACCESS_TOKEN }));
};
export const listEvent = async (stat, page, size = 6) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listEvent`, qs.stringify({ stat: stat, page: page, size: size, accessToken: Consts.ACCESS_TOKEN }));
};
export const listStore = async (storeType, page, size = 6) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listStore`, qs.stringify({ storeType: storeType, page: page, size: size, accessToken: Consts.ACCESS_TOKEN }));
};
export const listBanner = async (type, viewPage, size = 2) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listBanner`, qs.stringify({ type: type, viewPage: viewPage, size: size, accessToken: Consts.ACCESS_TOKEN }));
};
export const listPresentationDate = async (basedate) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listPresentationDate`, qs.stringify({ basedate: basedate, accessToken: Consts.ACCESS_TOKEN }));
};
export const getNotice = async (id) => {
	return instance.post(`${Consts.CLOSE_API_URL}/getNotice`, qs.stringify({ id: id, accessToken: Consts.ACCESS_TOKEN }));
};
export const getMedia = async (id) => {
	return instance.post(`${Consts.CLOSE_API_URL}/getMedia`, qs.stringify({ id: id, accessToken: Consts.ACCESS_TOKEN }));
};
export const getEvent = async (id) => {
	return instance.post(`${Consts.CLOSE_API_URL}/getEvent`, qs.stringify({ id: id, accessToken: Consts.ACCESS_TOKEN }));
};
export const getStore = async (id) => {
	return instance.post(`${Consts.CLOSE_API_URL}/getStore`, qs.stringify({ id: id, accessToken: Consts.ACCESS_TOKEN }));
};
export const getCertNumber = async (userTel) => {
	return instance.post(`${Consts.CLOSE_API_URL}/getCertNumber`, qs.stringify({ userTel: userTel, accessToken: Consts.ACCESS_TOKEN }));
};
export const getEventWedAmericano = async (userName, userTel) => {
	return instance.post(`${Consts.CLOSE_API_URL}/getEventWedAmericano`, qs.stringify({ userName: userName, userTel: userTel, accessToken: Consts.ACCESS_TOKEN }));
};
export const listEventWedAmericanoStore = async () => {
	return instance.post(`${Consts.CLOSE_API_URL}/listForEventWedAmericanoStore`, qs.stringify({ accessToken: Consts.ACCESS_TOKEN }));
};
export const getChecklist = async (id) => {
	return instance.post(`${Consts.CLOSE_API_URL}/getChecklist`, qs.stringify({ id: id, accessToken: Consts.ACCESS_TOKEN }));
};
export const listChecklist = async () => {
	return instance.post(`${Consts.CLOSE_API_URL}/listChecklist`, qs.stringify({ accessToken: Consts.ACCESS_TOKEN }));
};
export const listChecklistDetail = async (checklist_id) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listChecklistDetail`, qs.stringify({ checklist_id: checklist_id, accessToken: Consts.ACCESS_TOKEN }));
};
export const listChecklistMember = async () => {
	return instance.post(`${Consts.CLOSE_API_URL}/listChecklistMember`, qs.stringify({ accessToken: Consts.ACCESS_TOKEN }));
};
export const listBranch = async () => {
	return instance.post(`${Consts.CLOSE_API_URL}/listBranch`, qs.stringify({ accessToken: Consts.ACCESS_TOKEN }));
};
export const insertPartner = async (formData) => {
	return instanceForm.post(`${Consts.CLOSE_API_URL}/insertPartner`, formData);
};
export const insertFranchise = async (branchType, userName, userTel1, userTel2, userTel3, userEmail1, userEmail2, userWantArea, isBuildingOwner, ask) => {
	return instance.post(`${Consts.CLOSE_API_URL}/insertFranchise`, qs.stringify({ branchType: branchType, userName: userName, userTel1: userTel1, userTel2: userTel2, userTel3: userTel3, userEmail1: userEmail1, userEmail2: userEmail2, userWantArea: userWantArea, isBuildingOwner: isBuildingOwner, ask: ask, accessToken: Consts.ACCESS_TOKEN }));
};
export const insertChecklist = async (formData) => {
	return instanceForm.post(`${Consts.CLOSE_API_URL}/insertChecklist`, formData);
};
export const insertPresentation = async (branchType, userName, userTel1, userTel2, userTel3, recogFrom, wantCounseldate, wantCounseltime, adminMemo) => {
	return instance.post(`${Consts.CLOSE_API_URL}/insertPresentation`, qs.stringify({ branchType: branchType, userName: userName, userTel1: userTel1, userTel2: userTel2, userTel3: userTel3, recogFrom: recogFrom, wantCounseldate: wantCounseldate, wantCounseltime: wantCounseltime, adminMemo: adminMemo, accessToken: Consts.ACCESS_TOKEN }));
};
export const insertEventWedAmericano = async (step, userName, userTel, postcode, address, addressDetail, url, store_id) => {
	return instance.post(`${Consts.CLOSE_API_URL}/insertEventWedAmericano`, qs.stringify({ step: step, userName: userName, userTel: userTel, postcode: postcode, address: address, addressDetail: addressDetail, url: url, store_id: store_id, accessToken: Consts.ACCESS_TOKEN }));
};
export const updateBannerViewCount = async (id) => {
	return instance.post(`${Consts.CLOSE_API_URL}/updateBannerViewCount`, qs.stringify({ id: id, accessToken: Consts.ACCESS_TOKEN }));
};
export const listSales = async (size = 999) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listSales`, qs.stringify({ size: size, accessToken: Consts.ACCESS_TOKEN }));
};
export const listOpen = async (type, size = 999) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listOpen`, qs.stringify({ type: type, size: size, accessToken: Consts.ACCESS_TOKEN }));
};
export const listItem = async (type, page = 1, size = 8) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listItem`, qs.stringify({ type: type, page: page, size: size, accessToken: Consts.ACCESS_TOKEN }));
};
export const listReview = async (size = 8) => {
	return instance.post(`${Consts.CLOSE_API_URL}/listReview`, qs.stringify({ size: size, accessToken: Consts.ACCESS_TOKEN }));
};