import React, { Fragment, Component } from "react";
import { Link } from 'react-router-dom';
import LogoFooter from "../images/logo-footer.png";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <Fragment>
        <div className="Footer PC">
          <div className="SubContainer">
            <div className="MainContent">
              <div className="Logo">
                <img src={ LogoFooter } alt="" />
              </div>
              <div className="Address">
                ㈜피투피시스템즈 | 사업자등록번호 110-81-53273<br/>
                서울특별시 서대문구 연세로 10-1 즐거운빌딩 9층
              </div>
              <div className="Contact">
                <div className="Wrap">
                  <a href="https://youtu.be/watch?v=KMW8diPfsAU" target="_blank" rel="noopener noreferrer">
                    <button className="Instagram"></button>
                    <span>유튜브</span>
                  </a>
                  <a href="https://blog.naver.com/fastcafe2021/" target="_blank" rel="noopener noreferrer">
                    <button className="Naver"></button>
                    <span>네이버 블로그</span>
                  </a>
                </div>
                <div className="Wrap">
                  <a href="https://www.instagram.com/fastcafe_business/?igshid=p3q5advkbm81" target="_blank" rel="noopener noreferrer">
                    <button className="Instagram"></button>
                    <span>창업 인스타그램</span>
                  </a>
                  <a href="https://www.instagram.com/explore/tags/%ED%8C%A8%EC%8A%A4%ED%8A%B8%EC%B9%B4%ED%8E%98/" target="_blank" rel="noopener noreferrer">
                    <button className="Instagram"></button>
                    <span>#패스트카페</span>
                  </a>
                </div>
                <div className="Wrap">
                  <Link to="/partner">제휴/상품입점/협력 제안</Link>
                </div>
              </div>  
            </div>  
          </div>
        </div>  
        <div className="Footer Mobile">
          <div className="SubContainer">
            <div className="MainContent">
              <div className="Top">
                <p><Link to="/partner">제휴/상품입점/협력 제안</Link></p>
                <span>
                    ㈜피투피시스템즈 | 사업자등록번호 110-81-53273<br/>
                    서울특별시 서대문구 연세로 10-1 즐거운빌딩 9층
                </span>
              </div>
              <div className="Bottom">
                <div className="Wrap">
                  <a href="https://youtu.be/watch?v=KMW8diPfsAU" target="_blank" rel="noopener noreferrer">
                    <button className="Youtube"></button>
                    <span>유튜브</span>
                  </a>
                  <a href="https://blog.naver.com/fastcafe2021/" target="_blank" rel="noopener noreferrer">
                    <button className="Naver"></button>
                    <span>네이버 블로그</span>
                  </a>
                </div>
                <div className="Wrap">
                  <a href="https://www.instagram.com/fastcafe_business/?igshid=p3q5advkbm81" target="_blank" rel="noopener noreferrer">
                    <button className="Instagram"></button>
                    <span>창업 인스타그램</span>
                  </a>
                  <a href="https://www.instagram.com/explore/tags/%ED%8C%A8%EC%8A%A4%ED%8A%B8%EC%B9%B4%ED%8E%98/" target="_blank" rel="noopener noreferrer">
                    <button className="Instagram"></button>
                    <span>#패스트카페</span>
                  </a>
                </div>
              </div>
            </div>  
          </div>
        </div>  
      </Fragment>
    )
  }
}

export default Footer;
