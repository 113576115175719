import React, { Component } from 'react';
import * as Common from '../components/Common';
import { ClipLoader } from 'react-spinners';

class Loading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layerMarginTop: '',
      layerMarginLeft: '',
      style: null
    }
  }

  componentDidMount() {
    var _style = Common.getCenterPosStyle(this.layer, true);
    _style['zIndex'] = 99999;
    this.setState({ style: _style });
  }

  render() {
    return (
      <div className="layer-pop" style={this.state.style} ref={ref => this.layer = ref}>
        <div className="bg"></div>
        <ClipLoader color={'#D62E4A'} loading={true} size={80} />
      </div>
    )
  }
}

export default Loading;